import { useContext } from 'react';
import { Link } from 'react-router-dom';

import ThemeContext from '../../contexts/Theme';

export interface NavLinkProps {
  title: string;
  target: string;
  icon?: string;
  fontClassNames?: string;
  themeComponent?: string;
}

export default function NavLink({
  title,
  target,
  icon,
  fontClassNames = 'text-sm font-bold',
  themeComponent,
}: NavLinkProps) {
  const { colors } = useContext(ThemeContext);
  const colorClassNames =
    (themeComponent ? colors[themeComponent] : undefined) || colors.link;

  return (
    <Link
      to={target}
      className={`md:block text-left md:pb-2 mr-0 inline-block whitespace-nowrap p-4 px-0 ${fontClassNames} ${colorClassNames}`}>
      {icon && <i className={`fas fa-${icon} mr-2 text-sm`}></i>}
      <span>{title}</span>
    </Link>
  );
}

{
  /* <i className={`fas fa-${icon} mr-2 text-sm text-blueGray-300`}></i> */
}
