import AppConfig from '../config/app';
import Order from '../models/order';
import { PaginationParams } from '../models/pagination-params';
import ProviderCode from '../models/provider-code';
import { Factory } from '../utils/factory';
import GettGoOrdersServiceImpl from './orders-gettgo';

export interface GetOrdersParams {
  id?: string;
  keyword?: string;
  withInstallments?: boolean;
  pagination: PaginationParams;
}

export interface UpdateOrderStatusParams {
  orderId: number;
  status: string;
}

export default interface OrdersService {
  getOrders: (params: GetOrdersParams) => Promise<Order[]>;
  getOrder: (id: number) => Promise<Order>;
  updateOrderStatus: (params: UpdateOrderStatusParams) => Promise<Order>;
}

export class OrdersServiceFactory extends Factory<OrdersService> {
  constructor(appConfig: AppConfig) {
    super('OrdersService');

    const enabledProvidersSet = new Set(appConfig.enabledProviders);
    if (enabledProvidersSet.has(ProviderCode.GettGo)) {
      this.register(
        ProviderCode.GettGo,
        (opts: any) =>
          new GettGoOrdersServiceImpl({
            servicesConfig: appConfig.gettGoServices,
            session: opts.session,
          }),
        false
      );
    }
    //TODO: Hero
  }
}
