import React, { useEffect, useState } from 'react';

import defaultAvatar from '../../assets/img/react.jpg';
import { DropdownListGenerator } from './DropdownList';
import { DropdownItemProps } from './DropdownItem';

export interface UserDropdownProps {
  sessions?: UserSessionProps[];
  renderAction?: (session: UserSessionProps) => React.ReactNode;
}

export interface UserSessionProps {
  providerKey: string;
  username: string;
  displayName?: string;
  avatarUrl?: string;
  providerUrl?: string;
}

export default function UserDropdown({
  sessions,
  renderAction,
}: UserDropdownProps) {
  const [firstSession, setFirstSession] = useState<
    UserSessionProps | undefined
  >();

  useEffect(() => {
    if (!sessions || sessions.length < 1) {
      setFirstSession(undefined);
      return;
    }

    setFirstSession(sessions[0]);
  }, [sessions]);

  const listProps = {
    selectedValues: firstSession ? [firstSession] : undefined,
    themeComponent: 'userDropdown',
  };

  const itemGenProps = {
    renderer: (
      itemProps: React.PropsWithChildren<DropdownItemProps<UserSessionProps>>
    ) => {
      const { value: session, children } = itemProps;

      return (
        <div className="flex flex-wrap justify-center px-6">
          <div className="w-full px-4 flex justify-center">
            <div className="relative float-left">
              <img
                alt="User Avatar"
                src={session.avatarUrl || defaultAvatar}
                className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px max-h-150-px"
              />
            </div>
            <div className="relative float-left">
              {session.displayName || session.username}
            </div>
            {children}
            {renderAction && renderAction(session)}
          </div>
        </div>
      );
    },
  };

  return (
    <DropdownListGenerator
      values={sessions || []}
      listProps={listProps}
      itemGeneratorProps={itemGenProps}
    />
  );
}

// export interface UserSessionDropDownItemProps extends DropdownItemProps {
//   session: UserSessionProps;
//   fontClassNames?: string;
//   themeComponent?: string;
//   renderAction?: (session: UserSessionProps) => React.ReactNode;
//   onSelected?: (session: UserSessionProps) => void;
// }

// function UserSessionDropDownItem({
//   session,
//   fontClassNames = 'text-sm font-normal',
//   themeComponent,
//   renderAction,
//   onSelected,
// }: UserSessionDropDownItemProps) {
//   const { colors } = useContext(ThemeContext);
//   const colorClassNames =
//     (themeComponent ? colors[themeComponent] : undefined) || colors.link;

//   const { username, displayName, avatarUrl, providerUrl } = session;

//   function handleItemClicked(e: MouseEvent<HTMLAnchorElement>) {
//     console.log('UserSessionDropDownItem: clicked:', e);
//     onSelected && onSelected(session);
//   }

//   return (
//     <a
//       href={providerUrl || '#pablo'}
//       className={`text-sm py-2 px-4 block w-full whitespace-nowrap ${fontClassNames} ${colorClassNames}`}
//       onClick={handleItemClicked}></a>
//   );
// }

//Ref.: Original Components
// // dropdown props
// const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
// const btnDropdownRef = React.createRef<HTMLAnchorElement>(); // as RefObject<HTMLAnchorElement>;
// const popoverDropdownRef = React.createRef<HTMLDivElement>(); // as RefObject<HTMLDivElement>;
// const [selectedSession, setSelectedSession] = React.useState<
//   UserSessionProps | undefined
// >();

// function handleDropdownPopoverBtnClicked(e: MouseEvent) {
//   e.preventDefault();

//   if (!btnDropdownRef.current || !popoverDropdownRef.current) {
//     return;
//   }

//   const _newState = !dropdownPopoverShow;
//   setDropdownPopoverShow(_newState);

//   if (_newState) {
//     createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
//       placement: 'bottom-start',
//     });
//   }
// }

{
  /* <a
href="#pablo"
className={
  'text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
}
onClick={(e) => e.preventDefault()}>
Action
</a>
<a
href="#pablo"
className={
  'text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
}
onClick={(e) => e.preventDefault()}>
Another action
</a>
<a
href="#pablo"
className={
  'text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
}
onClick={(e) => e.preventDefault()}>
Something else here
</a>
<div className="h-0 my-2 border border-solid border-blueGray-100" />
<a
href="#pablo"
className={
  'text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
}
onClick={(e) => e.preventDefault()}>
Seprated link
</a> */
}
