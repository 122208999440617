import { createContext, useContext, useMemo } from 'react';

import AppConfig from '../config/app';

export interface AppContextProps {
  appConfig: AppConfig;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);
export default AppContext;

export interface AppContextProviderProps {
  appConfig: AppConfig;
}

export function AppContextProvider({
  appConfig,
  children,
}: React.PropsWithChildren<AppContextProviderProps>) {
  return (
    <AppContext.Provider value={{ appConfig }}>{children}</AppContext.Provider>
  );
}

export function useAppContext(): AppContextProps {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('AppContext Provider not found');
  }

  return context;
}
