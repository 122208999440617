import React, { ComponentPropsWithoutRef, useContext } from 'react';

import {
  TableCellGenerator,
  TableCellGeneratorProps,
  TableColumnProps,
  TableColumnHeaderCellFormatter,
} from './TableCell';
import { TableSectionContext, TableSectionContextProps } from './TableSection';
import ThemeContext from './../../contexts/Theme';
import { extractComponentProps } from '../../utils/component-utils';

//TODO: Revise the interfaces later

export type TableRowComponent = 'tr';
export type TableRowComponentHTMLProps =
  ComponentPropsWithoutRef<TableRowComponent>;

// Freeform/Custom Rows

export interface TableRowProps extends TableRowComponentHTMLProps {
  rowIndex: number;
  Component?: TableRowComponent;
  themeComponent?: string;
}

export default function TableRow(
  props: React.PropsWithChildren<TableRowProps>
) {
  const [htmlProps, { rowIndex, Component = 'tr', themeComponent, children }] =
    extractComponentProps(props, [
      'rowIndex',
      'Component',
      'themeComponent',
      'children',
    ]);

  const theme = useContext(ThemeContext);
  const section = useContext(TableSectionContext);

  const rowThemeComponents = section
    ? getRowThemeComponents(props, section)
    : [];

  const colorClassNames = theme.findColor(
    themeComponent,
    ...rowThemeComponents,
    'tableRow',
    'table',
    'primary'
  );

  return (
    <Component
      {...htmlProps}
      className={`w-100 ${colorClassNames} ${htmlProps.className || ''}`}
      data-row-index={rowIndex}>
      {children}
    </Component>
  );
}

function getRowThemeComponents(
  props: TableRowProps,
  section: TableSectionContextProps
): (string | undefined)[] {
  const { rowIndex } = props;

  let rowIndexThemeComponent: string | undefined;
  let sectionTypeThemeComponent: string | undefined;
  if (section) {
    switch (section.Component) {
      case 'thead':
        rowIndexThemeComponent =
          rowIndex % 2 > 0 ? 'tableHeaderOddRow' : 'tableHeaderEvenRow';
        sectionTypeThemeComponent = 'tableHeaderRow';
        break;
      case 'tfoot':
        rowIndexThemeComponent =
          rowIndex % 2 > 0 ? 'tableFooterOddRow' : 'tableFooterEvenRow';
        sectionTypeThemeComponent = 'tableFooterRow';
        break;
      default:
        rowIndexThemeComponent =
          rowIndex % 2 > 0 ? 'tableOddRow' : 'tableEvenRow';
        break;
    }
  }

  return [rowIndexThemeComponent, sectionTypeThemeComponent];
}

// Row Generator

export type TableRowRenderer<T> = (
  rowProps: React.PropsWithChildren<TableRowProps>,
  columns: TableColumnProps<any, T>[],
  row?: T
) => React.ReactNode;

export interface TableRowGeneratorProps<T> {
  columns: TableColumnProps<any, T>[];
  rowProps?: Partial<TableRowProps>;
  cellGeneratorProps?: Partial<TableCellGeneratorProps<any, T>>;
  row?: T;
  rowRenderer?: TableRowRenderer<T>;
}

export function TableRowGenerator<T>(
  props: React.PropsWithChildren<TableRowGeneratorProps<T>>
) {
  const { columns, rowProps, row, rowRenderer, cellGeneratorProps, children } =
    props;
  const { rowIndex = 0 } = rowProps || {};

  if (rowRenderer) {
    //delegate to Custom Renderer
    return (
      <>{rowRenderer({ ...rowProps, rowIndex, children }, columns, row)}</>
    );
  }

  return (
    <TableRow key={rowIndex} {...rowProps} rowIndex={rowIndex}>
      {columns.map((col, colIdx) => (
        <TableCellGenerator
          {...cellGeneratorProps}
          column={col}
          cellProps={{
            ...cellGeneratorProps?.cellProps,
            rowIndex: rowIndex,
            columnIndex: colIdx,
          }}
          row={row}
        />
      ))}
      {children}
    </TableRow>
  );
}

export function TableColumnsHeaderRowGenerator<T>(
  props: React.PropsWithChildren<TableRowGeneratorProps<T>>
) {
  const { children, cellGeneratorProps } = props;

  delete props.children;

  return (
    <TableRowGenerator
      {...props}
      cellGeneratorProps={{
        ...cellGeneratorProps,
        cellProps: {
          Component: 'th',
          themeComponent: 'tableHeaderCell',
          ...cellGeneratorProps?.cellProps,
        },
        cellFormatter: TableColumnHeaderCellFormatter,
      }}>
      {children}
    </TableRowGenerator>
  );
}
