/// Refactored into context by <dev@gettgo.com>

import React, { createContext } from 'react';

export interface ThemeContextDataProps {
  colors: Record<string, string>;
}

export enum ThemeName {
  Light = 'light',
  Dark = 'dark',
}

export class Theme implements ThemeContextDataProps {
  name: ThemeName;
  colors: Record<string, string>;

  constructor(name: ThemeName, props: ThemeContextDataProps) {
    this.name = name;
    this.colors = props.colors;
  }

  findColor(...searchKeys: (string | undefined)[]) {
    let colorClassNames: string | undefined;
    for (let key of searchKeys.filter((key) => !!key)) {
      colorClassNames = colorClassNames || this.colors[key!];
      if (colorClassNames) {
        break;
      }
    }

    return colorClassNames;
  }
}

export const DEFAULT_THEME = ThemeName.Light;

//Extracted from components
const Themes: Record<string, Theme> = [
  new Theme(ThemeName.Light, {
    colors: {
      primary: 'bg-white text-black',
      header: 'bg-lightBlue-500 text-white',
      //dashboardHeader: 'bg-lightBlue-500 text-white',
      button: 'bg-sky-600 text-white',
      // searchButton: 'bg-cyan-300',
      link: 'bg-transparent text-blueGray-700 hover:text-blueGray-500',
      sidebarLink: 'text-blueGray-600 hover:text-blueGray-500',
      dashboardLink: 'text-lightBlue-500 hover:text-lightBlue-600',
      form: 'bg-blueGray-100',
      formHeader: 'bg-blueGray-700 text-white',
      formSectionHeader: 'text-blueGray-400',
      formLabel: 'text-blueGray-600',
      dropdown: 'hover:text-blueGray-500 text-blueGray-700',
      tableHeader: 'bg-sky-700 text-white',
      tableHeaderCell: 'bg-sky-700 text-white border-gray-400',
      subTableHeader: 'bg-blueGray-500 text-white',
      tableCell: 'border-gray-200',
      statBox: 'bg-white text-blueGray-400 border-blueGray-400',
      statTitle: 'text-blueGray-700',
      statSubtitle: 'text-blueGray-400',
    },
  }),
  new Theme(ThemeName.Dark, {
    //TODO:
    colors: {
      primary: 'bg-blueGray-900 text-white',
      header: 'bg-blueGray-600 text-white',
      link: 'text-blueGray-200 hover:text-white',
      tableHeader: 'bg-lightBlue-900 text-white',
    },
  }),
].reduce((map, item) => ({ ...map, [item.name]: item }), {});

const ThemeContext = createContext<Theme>(Themes[DEFAULT_THEME]);
export default ThemeContext;

export interface ThemeContextProviderProps {
  name?: ThemeName;
}

export function ThemeContextProvider({
  name = DEFAULT_THEME,
  children,
}: React.PropsWithChildren<ThemeContextProviderProps>) {
  const themeData = Themes[name];

  return (
    <ThemeContext.Provider value={themeData}>{children}</ThemeContext.Provider>
  );
}
