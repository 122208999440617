import _ from 'lodash';

import ProviderCode from '../models/provider-code';
import GettGoServicesConfig from './gettgo-svcs';

export default class AppConfig {
  basePath: string = '';
  enabledProviders: ProviderCode[] = [];
  gettGoServices: GettGoServicesConfig = new GettGoServicesConfig();

  constructor(props?: Partial<AppConfig>) {
    props && Object.assign(this, props);
  }

  public applyEnvVars(envVars: Record<string, any>) {
    Object.entries(envVars).forEach(([key, value]) => {
      _.set(this, key, value);
    });
  }
}
