import React, { useContext, useEffect } from 'react';

import ThemeContext from '../../contexts/Theme';
import { extractComponentProps } from '../../utils/component-utils';

export type CollapseListComponent = 'div';

// Freeform/Custom Collapse List

export interface CollapseListProps
  extends React.ComponentPropsWithoutRef<CollapseListComponent> {
  collapsed?: boolean;
  placeholderElement?: React.ReactNode;
  buttonIconName?: string;
  Component?: CollapseListComponent;
  themeComponent?: string;
}

export default function CollapseList(
  props: React.PropsWithChildren<CollapseListProps>
) {
  const [
    htmlProps,
    {
      collapsed,
      placeholderElement,
      buttonIconName,
      Component = 'div',
      themeComponent,
      children,
    },
  ] = extractComponentProps(props, [
    'collapsed',
    'placeholderElement',
    'buttonIconName',
    'Component',
    'themeComponent',
    'children',
  ]);
  const { id, placeholder } = htmlProps;

  const theme = useContext(ThemeContext);

  const [currentShow, setCurrentShow] = React.useState(false);

  useEffect(() => {
    (collapsed || collapsed === false) && setCurrentShow(!collapsed);
  }, [collapsed]);

  const btnDropdownRef = React.createRef<HTMLAnchorElement>();
  const listBodyRef = React.createRef<HTMLDivElement>();

  function handleCollapseBtnClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    setCurrentShow(!currentShow);
  }

  return (
    <Component
      {...htmlProps}
      className={`collapse-list flex flex-col w-100 ${theme.findColor(
        themeComponent,
        'collapse',
        'primary'
      )}`}>
      <a
        href={`#${id || ''}`}
        className={`px-3 py-4 lg:py-2 flex flex-row w-100 items-center text-xs uppercase font-bold ${theme.findColor(
          'collapseButton',
          'button',
          'primary'
        )}`}
        ref={btnDropdownRef}
        onClick={handleCollapseBtnClick}>
        {placeholderElement || (
          <span className="flex flex-grow">{placeholder}</span>
        )}
        {
          <i
            className={`fas fa-${
              buttonIconName || (currentShow ? 'arrow-up' : 'arrow-down')
            }`}></i>
        }
      </a>
      <div
        ref={listBodyRef}
        className={`${
          currentShow ? 'block ' : 'hidden '
        } flex flex-col text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 ${theme.findColor(
          'collapseItems',
          'collapse',
          'primary'
        )}`}>
        {children}
      </div>
    </Component>
  );
}
