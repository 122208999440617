import Decimal from 'decimal.js';
import { FormatMoney } from 'format-money-js';

import TransactionsListView from './transactions-list';
import { useAppContext } from '../contexts/app';
import { useOrdersContext } from '../contexts/orders';
import Order from '../models/order';
import Table from '../templates/notus-react/src/components/Tables/Table';
import {
  TableColumnsHeaderRowGenerator,
  TableRowGenerator,
  TableRowProps,
} from '../templates/notus-react/src/components/Tables/TableRow';
import TableCell, {
  TableColumnProps,
} from '../templates/notus-react/src/components/Tables/TableCell';
import TableSection from '../templates/notus-react/src/components/Tables/TableSection';
import TableRow from '../templates/notus-react/src/components/Tables/TableRow';
import DropdownList, {
  DropdownListGenerator,
} from '../templates/notus-react/src/components/Dropdowns/DropdownList';

export interface OrdersTableViewProps {}

const numFormatter = new FormatMoney({ decimals: 2, symbol: '' });

const orderColumnDefs: TableColumnProps<any, Order>[] = [
  {
    name: 'id',
    title: 'ID',
    // fieldKey: 'id',
    defaultCellProps: {
      className: 'w-10 border-solid border-r-2',
    },
  },
  {
    name: 'payerName',
    title: 'Payer Name',
    fieldExtractor: (row: Order) =>
      `${row.payer.title ? row.payer.title + ' ' : ''}${row.payer.firstName} ${
        row.payer.lastName
      }`,
    defaultCellProps: {
      className: 'border-solid border-r-2 text-center',
    },
  },
  {
    name: 'grandTotal',
    title: 'Grand Total',
    // fieldKey: 'grandTotal',
    cellFormatter: (value: Decimal) =>
      numFormatter.from(value.toNumber(), {}) as string,
    defaultCellProps: {
      className: 'w-20 border-solid border-r-2 text-right',
    },
  },
  {
    name: 'status',
    title: 'Status',
    // fieldKey: 'status',
    // cellFormatter: (value: string) => orderStatusesMap[value],
    defaultCellProps: {
      className: 'w-10 border-solid border-r-2 text-center',
    },
  },
  {
    name: 'createdAt',
    title: 'Created Date',
    // fieldKey: 'status',
    cellFormatter: (value?: Date) => value?.toDateString(),
    defaultCellProps: {
      className: 'w-10 border-solid border-r-2 text-center',
    },
  },
  {
    name: 'updatedAt',
    title: 'Updated Date',
    // fieldKey: 'status',
    cellFormatter: (value?: Date) => value?.toDateString(),
    defaultCellProps: {
      className: 'w-10 border-solid border-r-2 text-center',
    },
  },
  {
    name: 'actions',
    //TODO:
    cellRenderer: (_value, _col, cellProps, row) => (
      <TableCell {...cellProps}>
        {row ? (
          <DropdownListGenerator
            values={[row?.remark]}
            listProps={{ buttonIconName: 'comment-dots', title: ' ' }}
          />
        ) : (
          <>Actions</>
        )}
      </TableCell>
    ),
    defaultCellProps: {
      className: 'w-10 border-solid border-r-2 text-center',
    },
  },
];

function OrdersTableView({}: OrdersTableViewProps) {
  const { appConfig } = useAppContext();
  const { loading, orders, error } = useOrdersContext();

  // if (loading) {
  //   return <div className="loading"></div>;
  // }

  const summaryColumnDefs: TableColumnProps<any, any>[] = [
    {
      name: 'totalLabel',
      cellFormatter: (_value, _col, _cellProps, _row) =>
        `Total: ${orders.length} Records`,
      defaultCellProps: {
        colSpan: orderColumnDefs.length,
        className: `${
          loading ? 'loading' : ''
        } text-center justify-center border-solid border-x-1 text-bold`,
      },
    },
  ];

  function renderOrderWithTransactionsRows(
    rowProps: TableRowProps,
    cols: TableColumnProps<any, Order>[],
    row?: Order
  ) {
    if (!row) {
      return null;
    }

    const { rowIndex } = rowProps;

    return (
      <>
        <TableRowGenerator columns={cols} row={row} />
        <TableRow rowIndex={rowIndex}>
          <TableCell
            rowIndex={rowIndex}
            columnIndex={0}
            colSpan={cols.length}
            className={'w-100 border-x-1'}>
            <TransactionsListView transactions={row.transactions} order={row} />
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <div className="list orders-list flex flex-col">
      {error && <div className="error">{error.message}</div>}
      <Table>
        <TableSection Component="thead">
          <TableColumnsHeaderRowGenerator
            columns={orderColumnDefs}
            rowProps={{ className: 'border border-b-2' }}
          />
        </TableSection>
        <TableSection Component="tbody">
          {!loading &&
            orders &&
            orders.map((order, rowIndex) => (
              <TableRowGenerator
                key={rowIndex}
                columns={orderColumnDefs}
                row={order}
                rowRenderer={renderOrderWithTransactionsRows}
              />
            ))}
        </TableSection>
        <TableSection Component="tfoot">
          <TableRowGenerator columns={summaryColumnDefs} />
        </TableSection>
      </Table>
    </div>
  );
}
export default OrdersTableView;

//Ref.: Original components:
// export interface OrdersListViewItemProps {
//   order: Order;
// }

// export function OrdersListViewItem({ order }: OrdersListViewItemProps) {
//   return (
//     <li className="w-full flex flex-col">
//       <div className="order-card w-full grid grid-cols-12 gap-2 my-2 border border-gray-200 shadow-md">
//         <span className="col-start-1 font-bold">ID#</span>
//         <span className="col-span-2 font-bold">{order.id}</span>
//         <span className="font-bold">Grand Total:</span>
//         <span className="col-span-2 font-bold">
//           {order.grandTotal.toFixed(2)}
//         </span>
//         <span className="col-start-1">Status:</span>
//         <span className="col-span-2">{order.status}</span>
//         <div className="transactions-card col-span-full mx-1 my-2 border border-gray-100 shadow-md">
//           <p className="w-full justify-center font-bold">Transactions</p>
//           <ul className="w-full">
//             {order.transactions.map((tran) => (
//               <li
//                 key={`${tran.id}`}
//                 className="w-full grid grid-cols-12 justify-items-center gap-2 border-b border-gray-100">
//                 <span className="col-start-1">ID#</span>
//                 <span className="col-span-2">{tran.id}</span>
//                 <span className="">Status:</span>
//                 <span className="col-span-2">{tran.status}</span>
//                 {tran.status === 'paid' && (
//                   <>
//                     <span className="col-start-1">Paid At:</span>
//                     <span className="col-span-2">
//                       {tran.paymentAt?.toLocaleString()}
//                     </span>
//                     <span className="">Channel:</span>
//                     <span className="col-span-2">{tran.channel}</span>
//                     <span className="">Amount:</span>
//                     <span className="col-span-2">
//                       {tran.actualPaid.toFixed(2)}
//                     </span>
//                   </>
//                 )}
//                 {order.installments && (
//                   <>
//                     <span className="col-start-1">Installment#:</span>
//                     <span className="col-span-2">{tran.installmentSeqNo}</span>
//                     <span className="">Amount:</span>
//                     <span className="col-span-2">
//                       {tran.installmentAmount.toFixed(2)}
//                     </span>
//                     <span className="">Due Date:</span>
//                     <span className="col-span-2">
//                       {tran.installmentDueAt?.toLocaleDateString()}
//                     </span>
//                   </>
//                 )}
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//     </li>
//   );
// }
