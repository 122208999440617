import NotFoundError from '../models/errors/not-found-error';

type ObjectSupplier<T> = (opts: any) => T;

interface ObjectSpec<T> {
  supplier: ObjectSupplier<T>;
  isSingleton: boolean;
}

export class Factory<T> {
  objTypeName?: string;
  registry: Record<string, ObjectSpec<T>> = {};
  singletons: Record<string, T> = {};

  constructor(objTypeName: string | undefined = undefined) {
    this.objTypeName = objTypeName;
  }

  public cleanup() {
    this.registry = {};
    this.singletons = {};
  }

  public get(key: string, opts: any = undefined) {
    if (key in this.singletons) {
      return this.singletons[key]!;
    }

    const { supplier, isSingleton } = this.registry[key];
    if (!supplier) {
      const err = new NotFoundError(
        `ObjectSupplier<${this.objTypeName || '<unknown>'}>`,
        key
      );
      // err.statusCode = 501;
      throw err;
    }

    const obj = supplier(opts);
    if (isSingleton) {
      this.singletons[key] = obj;
    }

    return obj;
  }

  public register(
    key: string,
    supplier: ObjectSupplier<T>,
    isSingleton: boolean = true
  ) {
    this.registry[key] = { supplier, isSingleton };
  }
}
