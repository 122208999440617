export default class ApiError extends Error {
  cause?: Error;

  constructor(message: string, cause: Error | undefined = undefined) {
    super(message);

    // restore prototype chain
    // ref.: https://stackoverflow.com/a/48342359
    if ('setPrototypeOf' in Object) {
      Object.setPrototypeOf(this, new.target.prototype);
    }

    this.cause = cause;
  }

  getStatusCode(): number {
    return 500;
  }
}
