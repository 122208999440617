// components

import React, { useContext } from 'react';

import ThemeContext from '../../contexts/Theme';

//TODO: Revise the interfaces later

export type FormComponent = 'form';
export type FormComponentHTMLProps =
  React.ComponentPropsWithoutRef<FormComponent>;

export interface SearchFormProps extends FormComponentHTMLProps {
  title?: string;
}

export default function SearchForm(
  props: React.PropsWithChildren<SearchFormProps>
) {
  const { title, children } = props;

  const htmlProps = { ...props };
  delete htmlProps.title;
  delete htmlProps.children;

  const theme = useContext(ThemeContext);

  return (
    <div
      className={`relative flex flex-col min-w-0 break-words w-full mb-6 gap-2 shadow-lg rounded-lg border-0 ${theme.findColor(
        'form',
        'primary'
      )} ${props.className || ''}`}>
      <form {...htmlProps} action={htmlProps.action || '#'}>
        {title && (
          <div className="rounded-t mb-0 px-6 py-6">
            <div className="text-center flex flex-col justify-between">
              <h6
                className={`flex flex-row text-xl font-bold ${theme.findColor(
                  'formHeader',
                  'header'
                )}`}>
                {title}
              </h6>
              {/* <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button">
              Settings
            </button> */}
            </div>
          </div>
        )}
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">{children}</div>
      </form>
    </div>
  );
}

//Ref.: Original components
{
  /* <form>
<h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
  User Information
</h6>
<div className="flex flex-wrap">
  <div className="w-full lg:w-6/12 px-4">
    <div className="relative w-full mb-3">
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor="grid-password">
        Username
      </label>
      <input
        type="text"
        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        defaultValue="lucky.jesse"
      />
    </div>
  </div>
  <div className="w-full lg:w-6/12 px-4">
    <div className="relative w-full mb-3">
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor="grid-password">
        Email address
      </label>
      <input
        type="email"
        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        defaultValue="jesse@example.com"
      />
    </div>
  </div>
  <div className="w-full lg:w-6/12 px-4">
    <div className="relative w-full mb-3">
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor="grid-password">
        First Name
      </label>
      <input
        type="text"
        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        defaultValue="Lucky"
      />
    </div>
  </div>
  <div className="w-full lg:w-6/12 px-4">
    <div className="relative w-full mb-3">
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor="grid-password">
        Last Name
      </label>
      <input
        type="text"
        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        defaultValue="Jesse"
      />
    </div>
  </div>
</div>

<hr className="mt-6 border-b-1 border-blueGray-300" />

<h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
  Contact Information
</h6>
<div className="flex flex-wrap">
  <div className="w-full lg:w-12/12 px-4">
    <div className="relative w-full mb-3">
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor="grid-password">
        Address
      </label>
      <input
        type="text"
        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
      />
    </div>
  </div>
  <div className="w-full lg:w-4/12 px-4">
    <div className="relative w-full mb-3">
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor="grid-password">
        City
      </label>
      <input
        type="email"
        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        defaultValue="New York"
      />
    </div>
  </div>
  <div className="w-full lg:w-4/12 px-4">
    <div className="relative w-full mb-3">
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor="grid-password">
        Country
      </label>
      <input
        type="text"
        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        defaultValue="United States"
      />
    </div>
  </div>
  <div className="w-full lg:w-4/12 px-4">
    <div className="relative w-full mb-3">
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor="grid-password">
        Postal Code
      </label>
      <input
        type="text"
        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        defaultValue="Postal Code"
      />
    </div>
  </div>
</div>

<hr className="mt-6 border-b-1 border-blueGray-300" />

<h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
  About Me
</h6>
<div className="flex flex-wrap">
  <div className="w-full lg:w-12/12 px-4">
    <div className="relative w-full mb-3">
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor="grid-password">
        About me
      </label>
      <textarea
        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        defaultValue="A beautiful UI Kit and Admin for React & Tailwind CSS. It is Free and Open Source."
        rows={4}></textarea>
    </div>
  </div>
</div>
</form> */
}
