import Decimal from 'decimal.js';
import { FormatMoney } from 'format-money-js';
import React, { useEffect, useMemo, useState } from 'react';
import Order from '../models/order';

import Transaction, { TransactionStatus } from '../models/transaction';
import CollapseList from '../templates/notus-react/src/components/Lists/CollapseList';
import { extractComponentProps } from '../templates/notus-react/src/utils/component-utils';

export interface TransactionsListSummaryProps {
  totalAmount: Decimal;
  totalPaidTransactions: number;
  totalPaidAmount: Decimal;
  totalUnpaidTransactions: number;
  totalUnpaidAmount: Decimal;
}

export interface TransactionsListViewProps {
  transactions: Transaction[];
  order: Order;
}

export default function TransactionsListView({
  transactions,
  order,
}: TransactionsListViewProps) {
  //TODO: Get Summary stats from backend?
  const summary = useMemo<TransactionsListSummaryProps>(() => {
    const _summary: TransactionsListSummaryProps = {
      totalAmount: new Decimal(0),
      totalPaidTransactions: 0,
      totalPaidAmount: new Decimal(0),
      totalUnpaidTransactions: 0,
      totalUnpaidAmount: new Decimal(0),
    };

    for (const tran of transactions) {
      if (tran.status === TransactionStatus.Paid) {
        _summary.totalPaidTransactions++;
        _summary.totalPaidAmount = _summary.totalPaidAmount.add(
          tran.actualPaid
        );
      } else {
        _summary.totalUnpaidTransactions++;
        _summary.totalUnpaidAmount = _summary.totalUnpaidAmount.add(
          tran.installmentAmount || order.grandTotal
        );
      }
    }
    _summary.totalAmount = _summary.totalPaidAmount.add(
      _summary.totalUnpaidAmount
    );

    return _summary;
  }, [transactions]);

  const numFormatter = new FormatMoney({ decimals: 2, symbol: '' });

  const paidAmountPercents = summary.totalAmount.greaterThan(0)
    ? summary.totalPaidAmount.mul(100).dividedBy(summary.totalAmount).toFixed(2)
    : '-';

  return (
    <div className="transactions-list w-100 flex flex-col">
      <CollapseList
        collapsed={true}
        placeholder={`Transactions (${transactions.length})`}>
        <ul className="flex flex-col w-100 gap-y-2">
          {transactions.map((tran, idx) => (
            <TransactionListItem
              key={idx}
              transaction={tran}
              order={order}
              index={idx}
            />
          ))}
        </ul>
        <p>Total: {transactions.length} transactions</p>
        <p>
          Paid: {summary.totalPaidTransactions} transactions,{' '}
          {numFormatter.from(summary.totalPaidAmount.toNumber(), {})} THB,{' '}
          {paidAmountPercents}%
        </p>
      </CollapseList>
    </div>
  );
}

export type TransactionListItemComponent = 'li';

export interface TransactionListItemProps
  extends React.ComponentPropsWithoutRef<TransactionListItemComponent> {
  transaction: Transaction;
  order: Order;
  index: number;
  Component?: TransactionListItemComponent;
  themeComponent?: string;
}

export function TransactionListItem(
  props: React.PropsWithChildren<TransactionListItemProps>
) {
  const [
    htmlProps,
    { transaction, order, index, Component = 'li', themeComponent, children },
  ] = extractComponentProps(props, [
    'transaction',
    'order',
    'index',
    'Component',
    'themeComponent',
    'children',
  ]);

  const numFormatter = new FormatMoney({ decimals: 2, symbol: '' });

  const now = new Date();
  const paid = transaction.status === TransactionStatus.Paid;
  const overdue =
    transaction.installmentDueAt &&
    (paid
      ? now >= transaction.installmentDueAt
      : transaction.paymentAt || now >= transaction.installmentDueAt);

  return (
    <Component className={`grid grid-cols-4 w-100 justify-between shadow-md`}>
      <span>ID# {transaction.id}</span>
      {/* className="border border-r-2 border-slate-500" */}
      <span>Status: {transaction.status}</span>
      <span>Created: {transaction.createdAt?.toLocaleString()}</span>
      <span>Updated: {transaction.updatedAt?.toLocaleString()}</span>
      {paid && (
        <>
          <span>
            Paid Amount:{' '}
            {numFormatter.from(transaction.actualPaid.toNumber(), {})},
          </span>
          <span>Paid At: {transaction.paymentAt?.toLocaleString()}</span>
          <span>Channel: {transaction.channel}</span>
          <span> </span>
        </>
      )}
      {(order.installments?.terms || 0) > 1 && (
        <>
          <span className={'text-bold'}>
            Installment# {transaction.installmentSeqNo}
          </span>
          <span>
            Amount:{' '}
            {numFormatter.from(transaction.installmentAmount.toNumber(), {})}
          </span>
          <span className={`${overdue ? 'text-red-500' : ''}`}>
            Due Date: {transaction.installmentDueAt?.toLocaleDateString()},
          </span>
          <span> </span>
        </>
      )}
      <span className="col-span-4">Remarks: {transaction.remark || ''}</span>
    </Component>
  );
}

//Ref.: Original components:
// export interface OrdersListViewItemProps {
//   order: Order;
// }

// export function OrdersListViewItem({ order }: OrdersListViewItemProps) {
//   return (
//     <li className="w-full flex flex-col">
//       <div className="order-card w-full grid grid-cols-12 gap-2 my-2 border border-gray-200 shadow-md">
//         <span className="col-start-1 font-bold">ID#</span>
//         <span className="col-span-2 font-bold">{order.id}</span>
//         <span className="font-bold">Grand Total:</span>
//         <span className="col-span-2 font-bold">
//           {order.grandTotal.toFixed(2)}
//         </span>
//         <span className="col-start-1">Status:</span>
//         <span className="col-span-2">{order.status}</span>
//         <div className="transactions-card col-span-full mx-1 my-2 border border-gray-100 shadow-md">
//           <p className="w-full justify-center font-bold">Transactions</p>
//           <ul className="w-full">
//             {order.transactions.map((tran) => (
//               <li
//                 key={`${tran.id}`}
//                 className="w-full grid grid-cols-12 justify-items-center gap-2 border-b border-gray-100">
//                 <span className="col-start-1">ID#</span>
//                 <span className="col-span-2">{tran.id}</span>
//                 <span className="">Status:</span>
//                 <span className="col-span-2">{tran.status}</span>
//                 {tran.status === 'paid' && (
//                   <>
//                     <span className="col-start-1">Paid At:</span>
//                     <span className="col-span-2">
//                       {tran.paymentAt?.toLocaleString()}
//                     </span>
//                     <span className="">Channel:</span>
//                     <span className="col-span-2">{tran.channel}</span>
//                     <span className="">Amount:</span>
//                     <span className="col-span-2">
//                       {tran.actualPaid.toFixed(2)}
//                     </span>
//                   </>
//                 )}
//                 {order.installments && (
//                   <>
//                     <span className="col-start-1">Installment#:</span>
//                     <span className="col-span-2">{tran.installmentSeqNo}</span>
//                     <span className="">Amount:</span>
//                     <span className="col-span-2">
//                       {tran.installmentAmount.toFixed(2)}
//                     </span>
//                     <span className="">Due Date:</span>
//                     <span className="col-span-2">
//                       {tran.installmentDueAt?.toLocaleDateString()}
//                     </span>
//                   </>
//                 )}
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//     </li>
//   );
// }
