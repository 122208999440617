import React, { useContext } from 'react';

// components

import ThemeContext from '../../contexts/Theme';
import { extractComponentProps } from '../../utils/component-utils';

export type FormFieldWrapperComponent = 'div';

export interface FormFieldWrapperProps
  extends React.ComponentPropsWithoutRef<FormFieldWrapperComponent> {
  label?: string;
  fieldElementId?: string;
  Component?: FormFieldWrapperComponent;
  themeComponent?: string;
}

//TODO: Revise this later
export default function FormFieldWrapper(
  props: React.PropsWithChildren<FormFieldWrapperProps>
) {
  const [htmlProps, { label, fieldElementId, Component = 'div', children }] =
    extractComponentProps(props, [
      'label',
      'fieldElementId',
      'Component',
      'children',
    ]);

  const theme = useContext(ThemeContext);

  return (
    <Component
      {...htmlProps}
      className={`relative mb-3 ${htmlProps.className || ''}`}>
      {label && (
        <label
          className={`block uppercase text-xs font-bold mb-2 ${theme.findColor(
            'formLabel',
            'form',
            'primary'
          )}`}
          htmlFor={fieldElementId}>
          {label}
        </label>
      )}
      {children}
    </Component>
  );
}
