import PropTypes from 'prop-types';
import { useContext } from 'react';

import ThemeContext from '../../contexts/Theme';

export enum StatArrowDirection {
  Up = 'up',
  Down = 'down',
}

export interface CardStatsProps {
  statTitle: string | number;
  statSubtitle?: string;
  statDescription?: string;
  statIconName?: string;
  statIconColor?: string;
  statArrow?: StatArrowDirection;
  statPercent?: string | number;
  statPercentColor?: string;
}

export default function CardStats({
  statTitle,
  statSubtitle = '',
  statDescription = '',
  statIconName,
  statIconColor,
  statArrow,
  statPercent,
  statPercentColor,
}: CardStatsProps) {
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <div
        className={`relative flex flex-col min-w-0 break-words rounded mb-6 xl:mb-0 shadow-lg ${
          colors.statBox || colors.primary
        }`}>
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5
                className={`uppercase font-bold text-xs ${
                  colors.statSubtitle || colors.primary
                }`}>
                {statSubtitle}
              </h5>
              <span
                className={`font-semibold text-xl ${
                  colors.statTitle || colors.primary
                }`}>
                {statTitle}
              </span>
            </div>
            <div className="relative w-auto pl-4 flex-initial">
              {statIconName && (
                <div className="p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full">
                  <i
                    className={`fas fa-${statIconName} ${
                      statIconColor ||
                      colors.statIcon ||
                      colors.statText ||
                      colors.statBox ||
                      colors.primary
                    }`}></i>
                </div>
              )}
            </div>
          </div>
          <p
            className={`text-sm mt-4 ${
              colors.statPercents || colors.statBox || colors.primary
            }`}>
            {(statArrow || statPercent) && (
              <span
                className={`mr-2 ${
                  statPercentColor || colors.statText || colors.primary
                }`}>
                <i
                  className={
                    statArrow === StatArrowDirection.Up
                      ? 'fas fa-arrow-up'
                      : statArrow === StatArrowDirection.Down
                      ? 'fas fa-arrow-down'
                      : ''
                  }></i>{' '}
                {statPercent && `${statPercent}%`}
              </span>
            )}
            <span className="whitespace-nowrap">{statDescription}</span>
          </p>
        </div>
      </div>
    </>
  );
}

CardStats.defaultProps = {
  // statSubtitle: 'Traffic',
  // statTitle: '350,897',
  // statArrow: 'up',
  // statPercent: '3.48',
  // statPercentColor: 'text-emerald-500',
  // statDescription: 'Since last month',
  // statIconName: 'far fa-chart-bar',
  // statIconColor: 'bg-red-500',
};

CardStats.propTypes = {
  statSubtitle: PropTypes.string,
  statTitle: PropTypes.any,
  statArrow: PropTypes.oneOf([StatArrowDirection.Up, StatArrowDirection.Down]),
  statPercent: PropTypes.any,
  // can be any of the text color utilities
  // from tailwindcss
  statPercentColor: PropTypes.string,
  statDescription: PropTypes.string,
  statIconName: PropTypes.string,
  // can be any of the background color utilities
  // from tailwindcss
  statIconColor: PropTypes.string,
};
