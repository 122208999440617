import React, { useContext } from 'react';

// components

import ThemeContext from '../../contexts/Theme';
import { extractComponentProps } from '../../utils/component-utils';

//TODO: Revise the interfaces

export type FormButtonComponent = 'button' | 'a';
export type FormButtonComponentHTMLProps =
  React.ComponentPropsWithoutRef<'button'> &
    React.ComponentPropsWithoutRef<'a'>;

export interface FormButtonProps extends FormButtonComponentHTMLProps {
  label?: string;
  icon?: string;
  Component?: FormButtonComponent;
  themeComponent?: string;
}

export default function FormButton(
  props: React.PropsWithChildren<FormButtonProps>
) {
  const [
    htmlProps,
    { label, icon, Component = 'div', themeComponent, children },
  ] = extractComponentProps(props, [
    'label',
    'icon',
    'Component',
    'themeComponent',
    'children',
  ]);

  const theme = useContext(ThemeContext);

  const colorClassNames = theme.findColor(
    themeComponent,
    'formButton',
    'button',
    'primary'
  );

  return (
    <Component
      {...htmlProps}
      className={`rounded-xl px-2 py-1 shadow-md ${colorClassNames} ${
        htmlProps.className || ''
      }`}>
      {icon && (
        <>
          <i className={`fas fa-${icon}`}></i>{' '}
        </>
      )}
      {label}
      {children}
    </Component>
  );
}
