/*eslint-disable*/
import React, { useContext } from 'react';
// import { Link } from 'react-router-dom';

// components

// import IndexDropdown from '..//Dropdowns/IndexDropdown';
import UserDropdown, { UserSessionProps } from '../Dropdowns/UserDropdown';
import NavLink, { NavLinkProps } from '../Links/NavLink';
import ThemeContext from '../../contexts/Theme';

export interface NavbarProps {
  appTitle: string;
  navLinks: NavLinkProps[];
  sessions?: UserSessionProps[];
}

export default function Navbar({ appTitle, navLinks, sessions }: NavbarProps) {
  const { colors } = useContext(ThemeContext);

  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <>
      <nav
        className={`top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg shadow ${colors.header}`}>
        <div className="container px-4 mx-auto flex flex-wrap gap-5 items-center justify-between">
          <div className="w-full relative flex flex-grow justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <NavLink
              target="/"
              title={appTitle}
              themeComponent="headerLink"
              fontClassNames="text-md font-bold"
            />
            {/* <Link
              to="/"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase">
              {appTitle}
            </Link> */}
            <button
              className={`cursor-pointer text-xl leading-none px-3 py-1 border border-solid rounded block lg:hidden outline-none focus:outline-none ${
                colors.button || colors.link
              }`}
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}>
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={`lg:flex items-center lg:bg-opacity-0 lg:shadow-none ${
              navbarOpen ? 'block' : 'hidden'
            }`}
            id="example-navbar-warning">
            {navLinks.map((navLinkProps) => (
              <NavLink
                key={navLinkProps.target}
                {...navLinkProps}
                themeComponent="headerLink"
              />
            ))}
            <UserDropdown sessions={sessions} />
          </div>
        </div>
      </nav>
    </>
  );
}

//Ref.: Original menu:
{
  /* <ul className="flex flex-col lg:flex-row list-none mr-auto">
  <li className="flex items-center">
    <Link
      className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
      to="/doc">
      <i className="text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2" />{' '}
      Docs
    </Link>
  </li>
</ul>
<ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
  <li className="flex items-center">
    <IndexDropdown />
  </li>
  <li className="flex items-center">
    <a
      className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
      href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdemos.creative-tim.com%2Fnotus-react%2F%23%2F"
      target="_blank">
      <i className="text-blueGray-400 fab fa-facebook text-lg leading-lg " />
      <span className="lg:hidden inline-block ml-2">Share</span>
    </a>
  </li>

  <li className="flex items-center">
    <a
      className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
      href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fdemos.creative-tim.com%2Fnotus-react%2F%23%2F&text=Start%20your%20development%20with%20a%20Free%20Tailwind%20CSS%20and%20React%20UI%20Kit%20and%20Admin.%20Let%20Notus%20React%20amaze%20you%20with%20its%20cool%20features%20and%20build%20tools%20and%20get%20your%20project%20to%20a%20whole%20new%20level.%20"
      target="_blank">
      <i className="text-blueGray-400 fab fa-twitter text-lg leading-lg " />
      <span className="lg:hidden inline-block ml-2">Tweet</span>
    </a>
  </li>

  <li className="flex items-center">
    <a
      className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
      href="https://github.com/creativetimofficial/notus-react?ref=nr-index-navbar"
      target="_blank">
      <i className="text-blueGray-400 fab fa-github text-lg leading-lg " />
      <span className="lg:hidden inline-block ml-2">Star</span>
    </a>
  </li>
</ul> */
}
