import Decimal from 'decimal.js';

import GettGoApiClient from './gettgo-api-client';
import OrdersService, {
  GetOrdersParams,
  UpdateOrderStatusParams,
} from './orders';
import GettGoServicesConfig from '../config/gettgo-svcs';
import { AuthToken } from '../models/auth-token';
import Order, { OrderStatus } from '../models/order';
import { TransactionStatus } from '../models/transaction';
import { SessionInfo } from '../models/session-info';
import NotFoundError from '../models/errors/not-found-error';
import ProviderCode from '../models/provider-code';

export interface GettGoOrdersServiceImplProps {
  servicesConfig: GettGoServicesConfig;
  session: SessionInfo;
}

/// Connects to GettGo API
class GettGoOrdersServiceImpl implements OrdersService {
  static ORDERS_ENDPOINT = 'api/orders';

  props: GettGoOrdersServiceImplProps;
  ggClient: GettGoApiClient;

  constructor(props: GettGoOrdersServiceImplProps) {
    this.props = props;
    this.ggClient = new GettGoApiClient({
      servicesConfig: props.servicesConfig,
    });
    props.session && this.ggClient.setCurrentSession(props.session);
  }

  /// Implements: OrdersService.getOrders()
  public async getOrders(params: GetOrdersParams): Promise<Order[]> {
    //TODO: Change from dummy data to real from GG API
    const respData = [
      {
        id: 1,
        status: OrderStatus.Issued,
        grandTotal: new Decimal(15400.0),
        paymentMethod: 'CARD',
        payer: {
          title: 'Ms.',
          firstName: 'Test',
          lastName: 'Customer',
        },
        remark: 'ทดสอบผ่อนเงินสด',
        createdAt: new Date(),
        updatedAt: new Date(),
        transactions: [
          {
            id: 1,
            orderId: 1,
            installmentSeqNo: 1,
            installmentAmount: new Decimal(5400.0),
            installmentDueAt: new Date('2022-01-01'),
            status: TransactionStatus.Paid,
            actualPaid: new Decimal(5400.0),
            channel: 'QR_CODE',
            remark: 'ทดสอบ',
            createdAt: new Date('2021-12-22'),
            updatedAt: new Date('2021-12-22'),
            paymentAt: new Date('2021-12-22'),
          },
          {
            id: 2,
            orderId: 1,
            installmentSeqNo: 2,
            installmentAmount: new Decimal(5000.0),
            installmentDueAt: new Date('2022-02-01'),
            status: TransactionStatus.WaitingForPayment,
            actualPaid: new Decimal(0.0),
            createdAt: new Date('2021-12-22'),
            updatedAt: new Date('2021-12-22'),
          },
          {
            id: 3,
            orderId: 1,
            installmentSeqNo: 3,
            installmentAmount: new Decimal(5000.0),
            installmentDueAt: new Date('2022-03-01'),
            status: TransactionStatus.WaitingForPayment,
            actualPaid: new Decimal(0.0),
            createdAt: new Date('2021-12-22'),
            updatedAt: new Date('2021-12-22'),
          },
        ],
        installments: {
          terms: 3,
          termsPaid: 1,
          termsOverdue: 1,
          startedDate: new Date('2021-12-22'),
          nextUnpaidSeqNo: 2,
          nextDueDate: new Date('2022-02-01'),
        },
      },
      {
        id: 2,
        status: OrderStatus.Paid,
        grandTotal: new Decimal(9999.0),
        paymentMethod: 'QR_CODE',
        payer: {
          title: 'Mr.',
          firstName: 'Test',
          lastName: 'Customer2',
        },
        createdAt: new Date(),
        updatedAt: new Date(),
        transactions: [
          {
            id: 4,
            orderId: 1,
            installmentSeqNo: 1,
            installmentAmount: new Decimal(9999.0),
            status: TransactionStatus.Paid,
            actualPaid: new Decimal(9999.0),
            channel: 'QR_CODE',
            createdAt: new Date('2021-12-22'),
            updatedAt: new Date('2021-12-22'),
            paymentAt: new Date('2021-12-22'),
          },
        ],
      },
    ];
    // const { id = "", keyword = "" } = params;
    // const reqParams = {
    //     id,
    //     keyword,
    //     withInstallments: `${params.withInstallments}`,
    //     pageSize: params.pagination.pageSize,
    //     pageIndex: params.pagination.pageIndex,
    // };
    // const respData = await this.ggClient.get<Order[]>(GettGoOrdersServiceImpl.ORDERS_ENDPOINT, reqParams);

    return (
      respData?.map((order) => ({ ...order, provider: ProviderCode.GettGo })) ||
      []
    );
  }

  /// Implements: OrdersService.getOrder()
  public async getOrder(id: number): Promise<Order> {
    const endpoint = `${GettGoOrdersServiceImpl.ORDERS_ENDPOINT}/${id}`;
    try {
      const respData = await this.ggClient.get<Order>(endpoint);
      respData.provider = ProviderCode.GettGo;

      return respData;
    } catch (err) {
      throw this.translateError(err, id);
    }
  }

  /// Implements: OrdersService.updateOrderStatus()
  public async updateOrderStatus(
    params: UpdateOrderStatusParams
  ): Promise<Order> {
    const endpoint = `${GettGoOrdersServiceImpl.ORDERS_ENDPOINT}/${params.orderId}/status`;
    try {
      const respData = await this.ggClient.put<UpdateOrderStatusParams, Order>(
        endpoint,
        params
      );
      respData.provider = ProviderCode.GettGo;

      return respData;
    } catch (err) {
      throw this.translateError(err, params.orderId);
    }
  }

  private translateError(err: any, orderId?: number) {
    if (!(err instanceof Error)) {
      return err;
    }

    if (err instanceof NotFoundError) {
      return new NotFoundError('Order', `${orderId}`, err);
    }

    return err;
  }
}
export default GettGoOrdersServiceImpl;
