import ApiError from './api-error';

export default class RemoteApiError extends ApiError {
  statusCode?: number;

  constructor(
    message: string,
    statusCode: number | undefined = undefined,
    cause: Error | undefined = undefined
  ) {
    super(message, cause);
    this.statusCode = statusCode;
  }

  getStatusCode(): number {
    return this.statusCode ?? 502;
  }
}
