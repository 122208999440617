export default class GettGoServicesConfig {
  baseUrl: string = '';
  apiKey?: string;
  secretKey?: string;
  [key: string]: any;

  constructor(props?: Partial<GettGoServicesConfig>) {
    props && Object.assign(this, props);
  }
}
