import React, { useContext } from 'react';

// components

import FormFieldWrapper from './FormFieldWrapper';
import ThemeContext from '../../contexts/Theme';
import { extractComponentProps } from '../../utils/component-utils';

//TODO: Revise the interfaces

export type FormSelectComponent = 'select';

export interface FormSelectProps
  extends React.ComponentPropsWithoutRef<FormSelectComponent> {
  label?: string;
  themeComponent?: string;
}

//TODO: Revise this later
export default function FormDropdown(
  props: React.PropsWithChildren<FormSelectProps>
) {
  const [htmlProps, { label, themeComponent, children }] =
    extractComponentProps(props, ['label', 'themeComponent', 'children']);

  const theme = useContext(ThemeContext);

  return (
    <FormFieldWrapper
      fieldElementId={htmlProps.id}
      label={label}
      className={''}>
      <select
        {...props}
        className={`border-0 px-3 py-3 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${theme.findColor(
          themeComponent,
          'formInput' || 'form' || 'primary'
        )} ${props.className || ''}`}>
        {children}
      </select>
    </FormFieldWrapper>
  );
}
