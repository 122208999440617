import SessionsService from './sessions';
import GettGoServicesConfig from '../config/gettgo-svcs';
import ProviderCode from '../models/provider-code';
import { SessionInfo } from '../models/session-info';
import GettGoApiClient from './gettgo-api-client';

export interface GettGoSessionsServiceImplProps {
  servicesConfig: GettGoServicesConfig;
}

/// Connects to GettGo API
export class GettGoSessionsServiceImpl implements SessionsService {
  static MY_SESSION_ENDPOINT = 'api/my_session';

  props: GettGoSessionsServiceImplProps;
  ggClient: GettGoApiClient;

  constructor(props: GettGoSessionsServiceImplProps) {
    this.props = props;
    this.ggClient = new GettGoApiClient({
      servicesConfig: props.servicesConfig,
    });
    //TEST
    console.log('gettGoServicesConfig:', props.servicesConfig);
  }

  /// Implements: SessionsService.getSessions()
  public async getCurrentSession(): Promise<SessionInfo> {
    //TODO: Change from dummy data to real from GG API
    const respData = {
      provider: ProviderCode.GettGo,
      id: '1234',
      username: 'admin',
      displayName: 'Admin GettGo',
      permissions: ['read', 'write'],
      invalidated: false,
      nOnceToken: 'xxxx',
      nOnceExpiresAt: new Date(new Date().getTime() + 300),
    };
    // try {
    //     respData = await this.ggClient.get<SessionInfo>(this.currentSession, GettGoSessionsServiceImpl.MY_SESSION_ENDPOINT);
    //     if (!resp?.result) {
    //         throw new Error("Failed requesting GettGo Sessions API");
    //     }
    // } catch (err) {
    //     if (!(err instanceof Error)) {
    //         throw err;
    //     }

    //     let statusCode: number | undefined;
    //     if ("statusCode" in err) {
    //         statusCode = err["statusCode"];
    //     }
    //     throw new InvalidSessionError(ProviderCode.GettGo, statusCode, err);
    // }
    this.ggClient.setCurrentSession(respData);

    return respData;
  }

  /// Implements: SessionsService.getNOnce()
  public async getNOnce(): Promise<SessionInfo> {
    const endpoint = `${GettGoSessionsServiceImpl.MY_SESSION_ENDPOINT}/nonce`;

    //TODO: Change from dummy data to real from GG API
    const t = new Date().getTime();
    const respData = {
      provider: ProviderCode.GettGo,
      id: '1234',
      username: 'admin',
      displayName: 'Admin GettGo',
      permissions: ['read', 'write'],
      invalidated: false,
      nOnceToken: `${t}`,
      nOnceExpiresAt: new Date(t + 300),
    };
    // try {
    //     const respData = await this.ggClient.get<SessionInfo>(endpoint, { });
    //     if (!respData) {
    //       throw new NotFoundError("Session", "");
    //     }
    //     return respData
    // } catch (err) {
    //     if (!(err instanceof Error)) {
    //         throw err;
    //     }

    //     let statusCode: number | undefined;
    //     if ("statusCode" in err) {
    //         statusCode = err["statusCode"];
    //     }
    //     throw new InvalidSessionError(ProviderCode.GettGo, statusCode, err);
    // }
    this.ggClient.setCurrentSession(respData);

    return respData;
  }
}
