import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import './App.css';
import { useAppContext } from './contexts/app';
import {
  SessionsContextProvider,
  useSessionsContext,
} from './contexts/sessions';
import HomeScreen from './components/home-screen';
import OrdersScreen from './components/orders-screen';
// import Sidebar from './components/sidebar';
import { SessionsServiceFactory } from './services/sessions';
import {
  ThemeContextProvider,
  ThemeName,
} from './templates/notus-react/src/contexts/Theme';
import DefaultLayout from './templates/notus-react/src/layouts/Default';

//TODO: Use navlinks from config
const navLinks = [
  { name: 'home', url: '/', title: 'Home', icon: 'home' },
  {
    name: 'orders',
    url: '/orders',
    title: 'Orders',
    icon: 'file-invoice-dollar',
  },
];

function App() {
  const { appConfig } = useAppContext();
  const [sessionsSvcFactory, setSessionsSvcFactory] = useState<
    SessionsServiceFactory | undefined
  >();

  useEffect(() => {
    setSessionsSvcFactory(new SessionsServiceFactory(appConfig));

    //cleanup
    return () => {
      sessionsSvcFactory?.cleanup();
    };
  }, []);

  return (
    <div className="App flex flex-col gap-2" role="app">
      <Router>
        <SessionsContextProvider
          providers={appConfig.enabledProviders}
          sessionsServiceFactory={sessionsSvcFactory}>
          <AppMainLayout />
        </SessionsContextProvider>
      </Router>
    </div>
  );
}
export default App;

function AppMainLayout() {
  const { loading, sessionsMap, error } = useSessionsContext();

  return (
    <ThemeContextProvider name={ThemeName.Light}>
      <DefaultLayout
        appTitle="MTB Installments Management"
        sidebarNavLinks={navLinks.map(({ title, url, icon }) => ({
          title,
          target: url,
          icon,
        }))}
        sessions={
          loading
            ? undefined
            : Object.entries(sessionsMap).map(([key, value]) => ({
                ...value,
                providerKey: key,
                avatarUrl: `assets/img/${key}.png`,
              }))
        }>
        <div id="mainBody" className="App-main-body flex flex-row gap-1">
          {/* <Sidebar links={navLinks} /> */}
          <div
            id="mainContent"
            className="App-main-content flex-grow min-h-screen">
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/orders" element={<OrdersScreen />} />
            </Routes>
          </div>
        </div>
      </DefaultLayout>
    </ThemeContextProvider>
  );
}
