import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

// import { library } from '@fortawesome/fontawesome-svg-core';
//TODO: Dynamic import icons?
// import {
//   faCheckSquare,
//   faFileInvoiceDollar,
//   faHome,
//   faSearch,
// } from '@fortawesome/free-solid-svg-icons';

import './index.css';
import App from './App';
import appConfigJSON from './app-config.json';
import reportWebVitals from './reportWebVitals';
import AppConfig from './config/app';
import { AppContextProvider } from './contexts/app';
//TODO:
// import './templates/notus-react/assets/styles/tailwind.css';

const appEnvironments: Record<string, any> = {
  envType: process.env.REACT_APP_ENV,
  'gettGoServices.baseUrl': process.env.REACT_APP_GETTGO_SVC_BASE_URL,
};

document.addEventListener('DOMContentLoaded', () => {
  const appConfig = new AppConfig(appConfigJSON as Partial<AppConfig>);
  appConfig.applyEnvVars(appEnvironments);

  // library.add(faCheckSquare, faFileInvoiceDollar, faHome, faSearch);

  ReactDOM.render(
    <React.StrictMode>
      <AppContextProvider appConfig={appConfig}>
        <App />
      </AppContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
