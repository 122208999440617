import ApiError from './api-error';

export default class InvalidSessionError extends ApiError {
  statusCode?: number;

  constructor(
    statusCode: number | undefined = undefined,
    cause: Error | undefined = undefined
  ) {
    super('Invalid Session', cause);
    this.statusCode = statusCode;
  }

  getStatusCode(): number {
    return this.statusCode ?? 401;
  }
}
