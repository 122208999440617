import React, { RefObject, useContext } from 'react';

import ThemeContext from '../../contexts/Theme';
import { DropdownListContext } from './DropdownList';
import { extractComponentProps } from '../../utils/component-utils';

export type DropdownItemComponent = 'div';

// Freeform/Custom Dropdown Item

export interface DropdownItemProps<T>
  extends React.ComponentPropsWithoutRef<DropdownItemComponent> {
  value: T;
  index: number;
  // onSelected?: (value: T, e: React.MouseEvent<HTMLAnchorElement>) => void;
  Component?: DropdownItemComponent;
  themeComponent?: string;
}

export function DropdownItem<T>(
  props: React.PropsWithChildren<DropdownItemProps<T>>
) {
  const [
    htmlProps,
    { value, index, Component = 'div', themeComponent, children },
  ] = extractComponentProps(props, [
    'value',
    'index',
    'Component',
    'themeComponent',
    'children',
  ]);

  const theme = useContext(ThemeContext);
  const parentContext = useContext(DropdownListContext);

  function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    parentContext && parentContext.onToggleSelect(value, index, e);
  }

  return (
    <Component
      {...htmlProps}
      className={`dropdown-item flex flex-row text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap ${theme.findColor(
        themeComponent,
        'dropdownItem',
        'primary'
      )}`}
      data-item-index={index}>
      <a id={props.id} href={`#${props.id || ''}`} onClick={handleClick}>
        {children}
      </a>
    </Component>
  );
}

// Item Generator

export type DropdownItemFormatter<T> = (value: T) => string | undefined;
export type DropdownItemRenderer<T> = (
  props: React.PropsWithChildren<DropdownItemProps<T>>
) => React.ReactNode;

export interface DropdownItemGeneratorProps<T> {
  itemProps: DropdownItemProps<T>;
  formatter?: DropdownItemFormatter<T>;
  renderer?: DropdownItemRenderer<T>;
}

export function DropdownItemGenerator<T>({
  itemProps,
  formatter,
  renderer,
  children,
}: React.PropsWithChildren<DropdownItemGeneratorProps<T>>) {
  const { value } = itemProps;

  //TODO: Revise this later
  const parentContext = useContext(DropdownListContext);

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    console.log('DropdownItemGenerator.handleClick:', e);
    if (parentContext) {
      if (parentContext.isSelected(value)) {
        parentContext.setPlaceholderElement(
          renderer ? renderer(itemProps) : valueStr
        );
      }
    }
  }

  let valueStr = renderer ? '' : formatter ? formatter(value) : `${value}`;

  return (
    <DropdownItem {...itemProps} onClick={handleClick}>
      {renderer ? <>{renderer(itemProps)}</> : valueStr}
      {children}
    </DropdownItem>
  );
}
