import Decimal from 'decimal.js';
import CustomerInfo from './customer-info';

import InstallmentsInfo from './installments-info';
import ProviderCode from './provider-code';
import Transaction from './transaction';

export default interface Order {
  provider?: ProviderCode;
  id: number;
  status: OrderStatus;
  paymentMethod: string;
  grandTotal: Decimal;
  payer: CustomerInfo;
  installments?: InstallmentsInfo;
  transactions: Transaction[];
  remark?: string;
  //TODO:
  createdAt?: Date;
  updatedAt?: Date;
}

export enum OrderStatus {
  Draft = 'draft',
  WaitingForPayment = 'waiting_for_payment',
  Paid = 'paid',
  Issued = 'issued',
}
