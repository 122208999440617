import React, { useEffect, useRef, useState } from 'react';

import OrdersTableView from './orders-table';
import OrdersSearchForm from './orders-search';
import { useAppContext } from '../contexts/app';
import { OrdersContextProvider } from '../contexts/orders';
import ProviderCode from '../models/provider-code';
import { GetOrdersParams, OrdersServiceFactory } from '../services/orders';

export interface OrdersScreenProps {}

function OrdersScreen({}: OrdersScreenProps) {
  const { appConfig } = useAppContext();

  const [providers, setProviders] = useState<ProviderCode[]>([]);
  const [params, setParams] = useState<GetOrdersParams | undefined>();

  const [ordersSvcFactory, setOrdersSvcFactory] = useState<
    OrdersServiceFactory | undefined
  >();

  useEffect(() => {
    setOrdersSvcFactory(new OrdersServiceFactory(appConfig));

    //cleanup
    return () => {
      ordersSvcFactory?.cleanup();
    };
  }, []);

  //TODO: Revise the flow
  async function handleSearch(
    providers: ProviderCode[],
    params: GetOrdersParams
  ) {
    setProviders(providers);
    setParams(params);
  }

  return (
    <div className="screen orders-screen w-full h-full flex flex-col">
      <div className="screen-title w-full flex flex-row justify-items-center content-center mb-2">
        <h1 className="flex mx-1 my-1 shadow-md">Orders</h1>
      </div>
      <OrdersContextProvider
        providers={providers}
        ordersServiceFactory={ordersSvcFactory}
        searchParams={params}>
        <OrdersSearchForm search={handleSearch} />
        <OrdersTableView />
      </OrdersContextProvider>
    </div>
  );
}
export default OrdersScreen;
