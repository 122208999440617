import _ from 'lodash';
import React from 'react';

// export interface ExtractComponentPropsResult<
//   TComp extends React.ElementType<any>,
//   TCustom
// > {
//   componentProps: React.ComponentPropsWithoutRef<TComp>;
//   customProps: TCustom;
// }

export function extractComponentProps<
  TComp extends React.ElementType<any>,
  TCustom extends object
>(
  props: TComp | TCustom,
  customKeys: string[]
): [React.ComponentPropsWithoutRef<TComp>, TCustom] {
  const componentProps = _.clone(props);
  const customProps = {};
  for (let key of customKeys) {
    (customProps as any)[key] = (props as any)[key];
    delete (componentProps as any)[key];
  }

  return [
    componentProps as React.ComponentPropsWithoutRef<TComp>,
    customProps as TCustom,
  ];
  //   return {
  //     componentProps: componentProps as React.ComponentPropsWithoutRef<TComp>,
  //     customProps: customProps as TCustom,
  //   };
}
