import { useEffect, useState, MouseEvent, ChangeEvent } from 'react';

import { useAppContext } from '../contexts/app';
import ProviderCode from '../models/provider-code';
import { GetOrdersParams } from '../services/orders';
import { useOrdersContext } from '../contexts/orders';
import FormButton from '../templates/notus-react/src/components/Forms/FormButton';
import FormSelect from '../templates/notus-react/src/components/Forms/FormSelect';
import FormInput from '../templates/notus-react/src/components/Forms/FormInput';
import FormSection from '../templates/notus-react/src/components/Forms/FormSection';
import Form from '../templates/notus-react/src/components/Forms/Form';

const DEFAULT_PAGE_SIZE = 20;

const initialParams: GetOrdersParams = {
  withInstallments: true,
  pagination: {
    pageSize: DEFAULT_PAGE_SIZE,
    pageIndex: 1,
  },
};

export interface OrdersSearchFormProps {
  search: (providers: ProviderCode[], params: GetOrdersParams) => Promise<void>;
  onProvidersChanged?: (providers: ProviderCode[]) => void;
  onParamsChanged?: (params: GetOrdersParams) => void;
  elementId?: string;
}

function OrdersSearchForm({
  search,
  onProvidersChanged,
  onParamsChanged,
  elementId = 'ordersSearchForm',
}: OrdersSearchFormProps) {
  const { appConfig } = useAppContext();
  const { loading: ordersLoading } = useOrdersContext();

  const [currentProviders, setCurrentProviders] = useState<ProviderCode[]>(
    appConfig.enabledProviders
  );
  const [currentParams, setCurrentParams] = useState<GetOrdersParams>({
    ...initialParams,
  });
  const [searchDisabled, setSearchDisabled] = useState(false);

  //TODO: Revise the flow
  useEffect(() => {
    setSearchDisabled(ordersLoading);
  }, [ordersLoading]);

  function handleProviderChanged(evt: ChangeEvent<HTMLSelectElement>) {
    console.log('handleProviderChanged:', evt);
    const _providers = [];
    const selOpts = evt.currentTarget.selectedOptions;
    for (let i = 0; i < selOpts.length; i++) {
      _providers.push(selOpts[i].value as ProviderCode);
    }
    setCurrentProviders(_providers);
    onProvidersChanged && onProvidersChanged(_providers);
  }

  function handleOrderIdFilterChanged(evt: ChangeEvent<HTMLInputElement>) {
    console.log('handleOrderIdFilterChanged:', evt);
    const _params = { ...currentParams, id: `${evt.currentTarget.value}` };
    setCurrentParams(_params);
    onParamsChanged && onParamsChanged(_params);
  }

  //TODO: Revise the flow
  function handleSearch(evt: MouseEvent<Element>) {
    evt.preventDefault();
    console.log('handleSearch:', evt);
    setSearchDisabled(true);
    (async function () {
      try {
        await search(currentProviders, currentParams);
        ordersLoading || setSearchDisabled(false);
      } catch (err) {
        console.error(err);
        ordersLoading || setSearchDisabled(false);
      }
    })();
  }

  return (
    <Form id={elementId} className="orders-search-form">
      <FormSection className="flex flex-col text-left justify-start align-top overflow-scroll">
        <FormSelect
          id={`${elementId}_provider`}
          label="Systems :"
          multiple={true}
          value={currentProviders}
          onChange={handleProviderChanged}
          className="required">
          {appConfig.enabledProviders.map((provider) => (
            <option key={provider} value={provider}>
              {provider}
            </option>
          ))}
        </FormSelect>
        <FormInput
          id={`${elementId}_orderID`}
          label="Order ID :"
          value={currentParams.id}
          onChange={handleOrderIdFilterChanged}
        />
        <div className="w-full flex flex-row text-start justify-start align-top overflow-scroll">
          <FormButton
            label="Search"
            icon="search"
            themeComponent="searchButton"
            disabled={searchDisabled}
            onClick={handleSearch}
          />
        </div>
      </FormSection>
    </Form>
  );
}
OrdersSearchForm.DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;
export default OrdersSearchForm;
