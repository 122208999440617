import React, { createContext, RefObject, useContext, useEffect } from 'react';
import { createPopper, OptionsGeneric } from '@popperjs/core';

import ThemeContext from '../../contexts/Theme';
import {
  DropdownItemGeneratorProps,
  DropdownItemGenerator,
  DropdownItemRenderer,
} from './DropdownItem';
import { extractComponentProps } from '../../utils/component-utils';

export type DropdownListComponent = 'div';

// Freeform/Custom Dropdown List

export interface DropdownListProps<T>
  extends React.ComponentPropsWithoutRef<DropdownListComponent> {
  multiple?: boolean;
  show?: boolean;
  //TODO: Revise this later
  selectedValues?: T[];
  placeholderElement?: React.ReactNode;
  popperOptions?: OptionsGeneric<any>;
  buttonIconName?: string;
  Component?: DropdownListComponent;
  themeComponent?: string;
}

export default function DropdownList<T>(
  props: React.PropsWithChildren<DropdownListProps<T>>
) {
  const [
    htmlProps,
    {
      placeholderElement,
      multiple,
      show,
      selectedValues,
      popperOptions,
      buttonIconName,
      Component = 'div',
      themeComponent,
      children,
    },
  ] = extractComponentProps(props, [
    'placeholderElement',
    'multiple',
    'show',
    'selectedValues',
    'popperOptions',
    'buttonIconName',
    'Component',
    'themeComponent',
    'children',
  ]);
  const { id, placeholder } = htmlProps;

  const theme = useContext(ThemeContext);

  const [currentSelectedValues, setCurrentSelectedValues] = React.useState<
    Set<T>
  >(new Set(selectedValues));
  const [lastSelectedValue, setLastSelectedValue] = React.useState<
    T | undefined
  >();
  //TODO: Revise this later
  const [currentPlaceholderElement, setCurrentPlaceholderElement] =
    React.useState<React.ReactNode>(undefined);
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);

  useEffect(() => {
    setCurrentSelectedValues(new Set(selectedValues));
    if (selectedValues) {
      let _lastSelVal =
        selectedValues.length >= 1
          ? selectedValues[selectedValues.length - 1]
          : undefined;
      setLastSelectedValue(_lastSelVal);
    }
  }, [selectedValues]);

  //TODO: Revise this later
  useEffect(() => {
    if (placeholderElement) {
      setCurrentPlaceholderElement(placeholderElement);
    } else if (placeholder) {
      setCurrentPlaceholderElement(
        <span className="flex flex-grow">{placeholder}</span>
      );
    }
  }, [placeholderElement, placeholder]);

  useEffect(() => {
    (show || show === false) && setDropdownPopoverShow(show);
  }, [show]);

  const btnDropdownRef = React.createRef() as RefObject<HTMLAnchorElement>;
  const popoverDropdownRef = React.createRef() as RefObject<HTMLDivElement>;

  function handleDropdownBtnClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    if (dropdownPopoverShow) {
      if (btnDropdownRef.current && popoverDropdownRef.current) {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
          strategy: 'absolute',
          placement: 'bottom-start',
          ...popperOptions,
        });
      }
    }
    setDropdownPopoverShow(!dropdownPopoverShow);
  }

  function isValueSelected(value: T) {
    return currentSelectedValues.has(value);
  }

  function handleItemToggleSelect(
    value: T,
    index: number,
    e: React.MouseEvent<HTMLElement>
  ) {
    console.log(`handleItemToggleSelect: [${index}]`, value, '; e:', e);
    const _newSelVals = new Set(currentSelectedValues);
    let _lastSelVal: T | undefined;
    if (_newSelVals.has(value)) {
      _newSelVals.delete(value);
      //TODO: Revise this later
      _newSelVals.forEach((val) => {
        _lastSelVal = val;
      });
    } else {
      if (!multiple) {
        _newSelVals.clear();
      }
      _newSelVals.add(value);
      _lastSelVal = value;
    }
    setCurrentSelectedValues(_newSelVals);
    setLastSelectedValue(_lastSelVal);
  }

  return (
    <Component
      {...htmlProps}
      className={`dropdown-list flex items-center ${theme.findColor(
        themeComponent,
        'dropdown',
        'primary'
      )}`}>
      <a
        href={`#${id || ''}`}
        className={`px-3 py-4 lg:py-2 flex w-100 items-center text-xs uppercase font-bold ${theme.findColor(
          'dropdownButton',
          'button',
          'primary'
        )}`}
        ref={btnDropdownRef}
        onClick={handleDropdownBtnClick}>
        {currentPlaceholderElement}
        {
          <i
            className={`fas fa-${
              buttonIconName ||
              (dropdownPopoverShow ? 'arrow-up' : 'arrow-down')
            }`}></i>
        }
      </a>
      <div
        ref={popoverDropdownRef}
        className={`${
          dropdownPopoverShow ? 'block ' : 'hidden '
        } text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 ${theme.findColor(
          'dropdownItems',
          'dropdown',
          'primary'
        )}`}>
        <DropdownListContext.Provider
          value={{
            selectedValues: currentSelectedValues,
            lastSelectedValue,
            isSelected: isValueSelected,
            onToggleSelect: handleItemToggleSelect,
            setPlaceholderElement: (el) => {
              //TEST
              console.log('setPlaceholderElement:', el);
              setCurrentPlaceholderElement(el);
            },
          }}>
          {children}
        </DropdownListContext.Provider>
      </div>
    </Component>
  );
}

export interface DropdownListContextProps<T> {
  selectedValues: Set<T>;
  lastSelectedValue: T | undefined;
  isSelected: (value: T) => boolean;
  onToggleSelect: (
    value: T,
    index: number,
    e: React.MouseEvent<HTMLElement>
  ) => void;
  //TODO: Revise this later
  setPlaceholderElement: (element: React.ReactNode) => void;
}

export const DropdownListContext = createContext<
  DropdownListContextProps<any> | undefined
>(undefined);

// List Generator

export interface DropdownListGeneratorProps<T> {
  values: T[];
  listProps?: Partial<DropdownListProps<T>>;
  itemGeneratorProps?: Partial<DropdownItemGeneratorProps<T>>;
  placeholderRenderer?: DropdownItemRenderer<string | undefined>;
}

export function DropdownListGenerator<T>({
  values,
  listProps,
  itemGeneratorProps,
  placeholderRenderer,
  children,
}: React.PropsWithChildren<DropdownListGeneratorProps<T>>) {
  const { placeholder } = listProps || {};
  const { itemProps } = itemGeneratorProps || {};

  return (
    <DropdownList
      {...listProps}
      placeholderElement={
        placeholderRenderer
          ? placeholderRenderer({ value: placeholder, index: -1 })
          : null
      }>
      {values.map((value, index) => (
        <DropdownItemGenerator
          key={index}
          {...itemGeneratorProps}
          itemProps={{ ...itemProps, value, index }}
        />
      ))}
      {children}
    </DropdownList>
  );
}

//Ref.: Original component:
{
  /* <>
<a
  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
  href="#pablo"
  ref={btnDropdownRef}
  onClick={(e) => {
    e.preventDefault();
    dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
  }}
>
  Demo Pages
</a>
<div
  ref={popoverDropdownRef}
  className={
    (dropdownPopoverShow ? "block " : "hidden ") +
    "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
  }
>
  <span
    className={
      "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
    }
  >
    Admin Layout
  </span>
  <Link
    to="/admin/dashboard"
    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Dashboard
  </Link>
  <Link
    to="/admin/settings"
    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Settings
  </Link>
  <Link
    to="/admin/tables"
    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Tables
  </Link>
  <Link
    to="/admin/maps"
    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Maps
  </Link>
  <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
  <span
    className={
      "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
    }
  >
    Auth Layout
  </span>
  <Link
    to="/auth/login"
    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Login
  </Link>
  <Link
    to="/auth/register"
    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Register
  </Link>
  <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
  <span
    className={
      "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
    }
  >
    No Layout
  </span>
  <Link
    to="/landing"
    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Landing
  </Link>
  <Link
    to="/profile"
    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Profile
  </Link>
</div>
</> */
}
