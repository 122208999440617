import ApiError from './api-error';

export default class NotFoundError extends ApiError {
  resType: string;
  resId: string;

  constructor(
    resType: string,
    resId: string,
    cause: Error | undefined = undefined
  ) {
    super(`${resType}#${resId} Not Found`, cause);
    this.resType = resType;
    this.resId = resId;
  }

  getStatusCode(): number {
    return 404;
  }
}
