import React, { useContext } from 'react';

import ThemeContext from '../../contexts/Theme';
import { extractComponentProps } from '../../utils/component-utils';

//TODO: Revise the interfaces

export type FormSectionComponent = 'section' | 'div';
export type FormSectionComponentHTMLProps =
  React.ComponentPropsWithoutRef<'section'> &
    React.ComponentPropsWithoutRef<'div'>;

export interface FormSectionProps extends FormSectionComponentHTMLProps {
  title?: string;
  Component?: FormSectionComponent;
}

export default function FormSection(
  props: React.PropsWithChildren<FormSectionProps>
) {
  const [htmlProps, { title, Component = 'div', children }] =
    extractComponentProps(props, ['title', 'Component', 'children']);

  const theme = useContext(ThemeContext);

  return (
    <Component
      {...htmlProps}
      className={`flex w-full ${theme.findColor(
        'formSection',
        'form',
        'primary'
      )} ${htmlProps.className || ''}`}>
      {title && (
        <h6
          className={`text-sm mt-3 mb-6 font-bold ${theme.findColor(
            'formSubtitle',
            'form',
            'primary'
          )}`}>
          {title}
        </h6>
      )}
      <div className="flex flex-wrap">
        <div className="w-full lg:w-6/12 px-4">{children}</div>
      </div>
    </Component>
  );
}
