import React, { createContext, useContext, useState } from 'react';

import { AuthToken } from '../models/auth-token';
import InvalidSessionError from '../models/errors/invalid-session-error';
import ProviderCode from '../models/provider-code';
import { SessionInfo } from '../models/session-info';
import { SessionsServiceFactory } from '../services/sessions';

export interface SessionsContextProps {
  loading: boolean;
  sessionsMap: Record<ProviderCode, SessionInfo>;
  error?: Error;
  reload: () => void;
  requestNOnce: (session: SessionInfo) => Promise<AuthToken>;
}

export const SessionsContext = createContext<SessionsContextProps | undefined>(
  undefined
);

export interface SessionsContextProviderProps {
  providers: ProviderCode[];
  sessionsServiceFactory?: SessionsServiceFactory;
  onError?: (err: Error) => void;
}

export const SessionsContextProvider = ({
  providers,
  sessionsServiceFactory,
  onError,
  children,
}: React.PropsWithChildren<SessionsContextProviderProps>) => {
  const [loading, setLoading] = useState(false);
  const [sessionsMap, setSessionsMap] = useState(
    {} as Record<ProviderCode, SessionInfo>
  );
  const [error, setError] = useState<Error | undefined>();

  React.useEffect(() => {
    //cleanup
    return () => {};
  }, []);

  React.useEffect(() => {
    reload();
  }, [sessionsServiceFactory]);

  function reload() {
    if (!sessionsServiceFactory) {
      return;
    }

    (async function () {
      setLoading(true);
      const proms = providers.map(async (provider) => {
        const sessionsSvc = sessionsServiceFactory.get(provider);
        const session = await sessionsSvc.getCurrentSession();
        if (session) {
          setSessionsMap({ ...sessionsMap, [provider]: session });
        }
        return session;
      });
      try {
        await Promise.all(proms);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
        setError(err as Error);
        onError && onError(err as Error);
      }
    })();
  }

  async function requestNOnce(session: SessionInfo) {
    try {
      if (!sessionsServiceFactory) {
        setLoading(true);
        throw new InvalidSessionError(503);
      }

      const sessionsSvc = sessionsServiceFactory.get(session.provider);
      const updatedSession = await sessionsSvc.getNOnce();
      if (updatedSession) {
        setSessionsMap({
          ...sessionsMap,
          [updatedSession.provider]: updatedSession,
        });
      }

      return {
        token: updatedSession.nOnceToken,
        expiresAt: updatedSession.nOnceExpiresAt,
      } as AuthToken;
    } catch (err) {
      console.error(err);
      setLoading(false);
      setError(err as Error);
      onError && onError(err as Error);
      throw err;
    }
  }

  return (
    <SessionsContext.Provider
      value={{ loading, sessionsMap, error, reload, requestNOnce }}>
      {children}
    </SessionsContext.Provider>
  );
};

export function useSessionsContext(): SessionsContextProps {
  const context = useContext(SessionsContext);
  if (!context) {
    throw new Error('SessionsContext Provider not found');
  }

  return context;
}
