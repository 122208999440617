import React, { useContext } from 'react';
// import PropTypes from 'prop-types';

// components

import ThemeContext from '../../contexts/Theme';
import { extractComponentProps } from '../../utils/component-utils';
// import TableDropdown from '../Dropdowns/TableDropdown';

export type TableComponent = 'table';

export interface TableProps
  extends React.ComponentPropsWithoutRef<TableComponent> {
  title?: string;
}

export default function Table(props: React.PropsWithChildren<TableProps>) {
  const [htmlProps, { title, children }] = extractComponentProps(props, [
    'title',
    'Component',
    'children',
  ]);

  const theme = useContext(ThemeContext);

  return (
    <div className={`table-wrapper flex flex-col w-full`}>
      <div
        className={`relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ${theme.findColor(
          'table',
          'primary'
        )}`}>
        {title && (
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={`font-semibold text-lg ${theme.findColor(
                    'tableHeader',
                    'primary'
                  )}`}>
                  {title}
                </h3>
              </div>
            </div>
          </div>
        )}
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table
            {...htmlProps}
            className={`items-center w-full bg-transparent border-collapse ${theme.findColor(
              'table',
              'primary'
            )} ${htmlProps?.className || ''}`}>
            {children}
          </table>
        </div>
      </div>
    </div>
  );
}

// Table.defaultProps = {};

// Table.propTypes = {};

//References from original template:
/* <tr>
  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
    <img
      src={require('assets/img/bootstrap.jpg').default}
      className="h-12 w-12 bg-white rounded-full border"
      alt="..."></img>{' '}
    <span
      className={
        'ml-3 font-bold ' +
        +(color === 'light' ? 'text-blueGray-600' : 'text-white')
      }>
      Argon Design System
    </span>
  </th>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    $2,500 USD
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <i className="fas fa-circle text-orange-500 mr-2"></i> pending
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <div className="flex">
      <img
        src={require('assets/img/team-1-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"></img>
      <img
        src={require('assets/img/team-2-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
      <img
        src={require('assets/img/team-3-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
      <img
        src={require('assets/img/team-4-470x470.png').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
    </div>
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <div className="flex items-center">
      <span className="mr-2">60%</span>
      <div className="relative w-full">
        <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
          <div
            style={{ width: '60%' }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"></div>
        </div>
      </div>
    </div>
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
    <TableDropdown />
  </td>
</tr>
<tr>
  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
    <img
      src={require('assets/img/angular.jpg').default}
      className="h-12 w-12 bg-white rounded-full border"
      alt="..."></img>{' '}
    <span
      className={
        'ml-3 font-bold ' +
        +(color === 'light' ? 'text-blueGray-600' : 'text-white')
      }>
      Angular Now UI Kit PRO
    </span>
  </th>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    $1,800 USD
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <i className="fas fa-circle text-emerald-500 mr-2"></i>{' '}
    completed
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <div className="flex">
      <img
        src={require('assets/img/team-1-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"></img>
      <img
        src={require('assets/img/team-2-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
      <img
        src={require('assets/img/team-3-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
      <img
        src={require('assets/img/team-4-470x470.png').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
    </div>
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <div className="flex items-center">
      <span className="mr-2">100%</span>
      <div className="relative w-full">
        <div className="overflow-hidden h-2 text-xs flex rounded bg-emerald-200">
          <div
            style={{ width: '100%' }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-emerald-500"></div>
        </div>
      </div>
    </div>
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
    <TableDropdown />
  </td>
</tr>
<tr>
  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
    <img
      src={require('assets/img/sketch.jpg').default}
      className="h-12 w-12 bg-white rounded-full border"
      alt="..."></img>{' '}
    <span
      className={
        'ml-3 font-bold ' +
        +(color === 'light' ? 'text-blueGray-600' : 'text-white')
      }>
      Black Dashboard Sketch
    </span>
  </th>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    $3,150 USD
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <i className="fas fa-circle text-red-500 mr-2"></i> delayed
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <div className="flex">
      <img
        src={require('assets/img/team-1-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"></img>
      <img
        src={require('assets/img/team-2-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
      <img
        src={require('assets/img/team-3-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
      <img
        src={require('assets/img/team-4-470x470.png').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
    </div>
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <div className="flex items-center">
      <span className="mr-2">73%</span>
      <div className="relative w-full">
        <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
          <div
            style={{ width: '73%' }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"></div>
        </div>
      </div>
    </div>
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
    <TableDropdown />
  </td>
</tr>
<tr>
  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
    <img
      src={require('assets/img/react.jpg').default}
      className="h-12 w-12 bg-white rounded-full border"
      alt="..."></img>{' '}
    <span
      className={
        'ml-3 font-bold ' +
        +(color === 'light' ? 'text-blueGray-600' : 'text-white')
      }>
      React Material Dashboard
    </span>
  </th>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    $4,400 USD
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <i className="fas fa-circle text-teal-500 mr-2"></i> on
    schedule
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <div className="flex">
      <img
        src={require('assets/img/team-1-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"></img>
      <img
        src={require('assets/img/team-2-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
      <img
        src={require('assets/img/team-3-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
      <img
        src={require('assets/img/team-4-470x470.png').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
    </div>
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <div className="flex items-center">
      <span className="mr-2">90%</span>
      <div className="relative w-full">
        <div className="overflow-hidden h-2 text-xs flex rounded bg-teal-200">
          <div
            style={{ width: '90%' }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"></div>
        </div>
      </div>
    </div>
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
    <TableDropdown />
  </td>
</tr>
<tr>
  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
    <img
      src={require('assets/img/vue.jpg').default}
      className="h-12 w-12 bg-white rounded-full border"
      alt="..."></img>{' '}
    <span
      className={
        'ml-3 font-bold ' +
        +(color === 'light' ? 'text-blueGray-600' : 'text-white')
      }>
      React Material Dashboard
    </span>
  </th>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    $2,200 USD
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <i className="fas fa-circle text-emerald-500 mr-2"></i>{' '}
    completed
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <div className="flex">
      <img
        src={require('assets/img/team-1-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"></img>
      <img
        src={require('assets/img/team-2-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
      <img
        src={require('assets/img/team-3-800x800.jpg').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
      <img
        src={require('assets/img/team-4-470x470.png').default}
        alt="..."
        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"></img>
    </div>
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
    <div className="flex items-center">
      <span className="mr-2">100%</span>
      <div className="relative w-full">
        <div className="overflow-hidden h-2 text-xs flex rounded bg-emerald-200">
          <div
            style={{ width: '100%' }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-emerald-500"></div>
        </div>
      </div>
    </div>
  </td>
  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
    <TableDropdown />
  </td>
</tr> */
