import { ComponentPropsWithoutRef, useContext } from 'react';

import { TableSectionContext, TableSectionContextProps } from './TableSection';
import ThemeContext from './../../contexts/Theme';
import { extractComponentProps } from '../../utils/component-utils';
import _ from 'lodash';

//TODO: Revise the interfaces later

export type TableCellComponent = 'td' | 'th';
export type TableCellComponentHTMLProps = ComponentPropsWithoutRef<'td'> &
  ComponentPropsWithoutRef<'th'>;

// Freeform/Custom Cells

export interface TableCellProps extends TableCellComponentHTMLProps {
  rowIndex: number;
  columnIndex: number;
  Component?: TableCellComponent;
  themeComponent?: string;
}

export default function TableCell(
  props: React.PropsWithChildren<TableCellProps>
) {
  const [
    htmlProps,
    { rowIndex, columnIndex, Component = 'td', themeComponent, children },
  ] = extractComponentProps(props, [
    'content',
    'rowIndex',
    'columnIndex',
    'Component',
    'themeComponent',
    'children',
  ]);

  const theme = useContext(ThemeContext);
  const section = useContext(TableSectionContext);

  const cellThemeComponents = section
    ? getCellThemeComponents(props, section)
    : [];

  const baseClassNames = 'px-6 align-middle text-xs whitespace-nowrap p-4';
  let colorClassNames = theme.findColor(
    themeComponent,
    ...cellThemeComponents,
    'tableCell',
    'table',
    'primary'
  );
  let cellTypeClassNames = '';
  switch (Component) {
    case 'th':
      cellTypeClassNames = 'py-3 text-xs font-semibold text-left items-center';
      //gray
      // (colorTheme === 'light'
      //   ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
      //   : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700');
      break;
    default:
      break;
  }

  return (
    <Component
      {...htmlProps}
      className={`${[baseClassNames, cellTypeClassNames, colorClassNames].join(
        ' '
      )} ${htmlProps.className || ''}`}
      data-row-index={rowIndex}
      data-column-index={columnIndex}>
      {children}
    </Component>
  );
}

function getCellThemeComponents(
  props: TableCellProps,
  section: TableSectionContextProps
): (string | undefined)[] {
  const { rowIndex } = props;

  let rowIndexThemeComponent: string | undefined;
  let sectionTypeThemeComponent: string | undefined;
  if (section) {
    switch (section.Component) {
      case 'thead':
        rowIndexThemeComponent =
          rowIndex % 2 > 0 ? 'tableHeaderOddRowCell' : 'tableHeaderEvenRowCell';
        sectionTypeThemeComponent = 'tableHeaderCell';
        break;
      case 'tfoot':
        rowIndexThemeComponent =
          rowIndex % 2 > 0 ? 'tableFooterOddRowCell' : 'tableFooterEvenRowCell';
        sectionTypeThemeComponent = 'tableFooterCell';
        break;
      default:
        rowIndexThemeComponent =
          rowIndex % 2 > 0 ? 'tableOddRowCell' : 'tableEvenRowCell';
        break;
    }
  }

  return [rowIndexThemeComponent, sectionTypeThemeComponent];
}

// Cell Generator

export interface TableColumnProps<TCell, TRow> {
  name: string;
  title?: string;
  fieldKey?: keyof TRow;
  fieldExtractor?: (row: TRow) => TCell;
  defaultCellProps?: Partial<TableCellProps>;
  cellFormatter?: TableCellFormatter<TCell, TRow>;
  cellRenderer?: TableCellRenderer<TCell, TRow>;
}

export type TableCellFormatter<TCell, TRow> = (
  value: TCell | undefined,
  column: TableColumnProps<TCell, TRow>,
  cellProps: React.PropsWithChildren<TableCellProps>,
  row?: TRow
) => string | undefined;
export type TableCellRenderer<TCell, TRow> = (
  value: TCell | undefined,
  column: TableColumnProps<TCell, TRow>,
  cellProps: React.PropsWithChildren<TableCellProps>,
  row?: TRow
) => React.ReactNode;

export interface TableCellGeneratorProps<TCell, TRow> {
  column: TableColumnProps<TCell, TRow>;
  // Props in here will override those in column
  cellProps: Partial<TableCellProps>;
  row?: TRow;
  ///overrides column.cellFormatter
  cellFormatter?: TableCellFormatter<TCell, TRow>;
  ///overrides column.cellRenderer
  cellRenderer?: TableCellRenderer<TCell, TRow>;
}

export function TableCellGenerator<TCell, TRow>({
  column,
  cellProps,
  row,
  cellFormatter,
  cellRenderer,
  children,
}: React.PropsWithChildren<TableCellGeneratorProps<TCell, TRow>>) {
  const effCellPropsPartial = {
    ...column.defaultCellProps,
    ...cellProps,
  } as Partial<TableCellProps>;
  const { rowIndex = 0, columnIndex = 0 } = effCellPropsPartial;
  const effCellProps = {
    ...effCellPropsPartial,
    rowIndex,
    columnIndex,
  };

  let fieldValue: TCell | undefined;
  if (row && column.fieldExtractor) {
    fieldValue = column.fieldExtractor(row);
  } else if (row && column.fieldKey) {
    fieldValue = _.get(row, column.fieldKey) as unknown as TCell;
  } else if (row && column.name in row) {
    fieldValue = (row as any)[column.name] as TCell;
  }

  const renderer = cellRenderer || column.cellRenderer;
  if (renderer) {
    //delegate to Custom Renderer
    return <>{renderer(fieldValue, column, effCellProps, row)}</>;
  }

  const formatter = cellFormatter || column.cellFormatter;
  let cellValStr = formatter
    ? formatter(fieldValue, column, effCellProps, row)
    : `${fieldValue ?? ''}`;

  return (
    <TableCell {...effCellProps}>
      {cellValStr}
      {children}
    </TableCell>
  );
}

export function TableColumnHeaderCellFormatter<TCell, TRow>(
  _value: TCell,
  column: TableColumnProps<TCell, TRow>,
  _cellProps: React.PropsWithChildren<TableCellProps>,
  _row?: TRow
) {
  return column.title || column.name;
}
