// import React, { useEffect, useRef, useState } from 'react';
import { FormatMoney } from 'format-money-js';

// import { useAppContext } from '../contexts/app';
import CardStats, {
  StatArrowDirection,
} from '../templates/notus-react/src/components/Cards/CardStats';
// import AdminDashboardView from '../templates/notus-react/src/views/admin/Dashboard';

export interface HomeScreenProps {}

function HomeScreen({}: HomeScreenProps) {
  // const { appConfig } = useAppContext();

  //TODO: Add API for real data
  const dummyStats = {
    totalTransactions: 10000,
    totalAmount: 6000000,
    collectedTransactions: 9500,
    collectedAmount: 5550000,
    overdueTransactions: 100,
    overdueAmount: 50000,
    collectedPercents: 0,
    collectedAmountPercents: 0,
    overduePercents: 0,
    overdueAmountPercents: 0,
  };
  dummyStats.collectedPercents =
    (dummyStats.collectedTransactions * 100) / dummyStats.totalTransactions;
  dummyStats.collectedAmountPercents =
    (dummyStats.collectedAmount * 100) / dummyStats.totalAmount;
  dummyStats.overduePercents =
    (dummyStats.overdueTransactions * 100) / dummyStats.totalTransactions;
  dummyStats.overdueAmountPercents =
    (dummyStats.overdueAmount * 100) / dummyStats.totalAmount;

  const numFormatter = new FormatMoney({ decimals: 2, symbol: '' });

  return (
    <div className="screen home-screen w-full h-full flex flex-col">
      <div className="screen-title w-full flex flex-row justify-items-center content-center mb-2">
        <h1 className="flex mx-1 my-1 shadow-md">Home</h1>
      </div>
      <div className="flex grid-cols-4">
        <CardStats
          statSubtitle="(TEST) This Month Collected - Transactions"
          statTitle={dummyStats.collectedTransactions}
          statIconName="vote-yea"
          statArrow={StatArrowDirection.Up}
          statPercent={dummyStats.collectedPercents.toFixed(2)}
        />
        <CardStats
          statSubtitle="(TEST) This Month Collected - Amount"
          statTitle={
            numFormatter.from(dummyStats.collectedAmount, {}) as string
          }
          statIconName="file-invoice-dollar"
          statArrow={StatArrowDirection.Up}
          statPercent={dummyStats.collectedAmountPercents.toFixed(2)}
        />
        <CardStats
          statSubtitle="(TEST) This Month Overdue - Transactions"
          statTitle={`${dummyStats.overdueTransactions}`}
          statIconName="calendar-xmark"
          statArrow={StatArrowDirection.Down}
          statPercent={dummyStats.overduePercents.toFixed(2)}
        />
        <CardStats
          statSubtitle="(TEST) This Month Overdue - Amount"
          statTitle={numFormatter.from(dummyStats.overdueAmount, {}) as string}
          statIconName="business-time"
          statArrow={StatArrowDirection.Down}
          statPercent={dummyStats.overdueAmountPercents.toFixed(2)}
        />
      </div>
    </div>
  );
}
export default HomeScreen;
