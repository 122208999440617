import { Decimal } from 'decimal.js';

export default interface Transaction {
  id: number;
  orderId: number;
  installmentSeqNo: number;
  installmentAmount: Decimal;
  installmentDueAt?: Date;
  actualPaid: Decimal;
  status: TransactionStatus;
  channel?: string;
  remark?: string;
  //TODO:
  createdAt?: Date;
  updatedAt?: Date;
  paymentAt?: Date;
}

export enum TransactionStatus {
  WaitingForPayment = 'waiting_for_payment',
  Paid = 'paid',
  Error = 'error',
}
