import React, { createContext, useContext } from 'react';

import ThemeContext from './../../contexts/Theme';
import { extractComponentProps } from '../../utils/component-utils';

//TODO: Revise the interfaces later

export type TableSectionComponent = 'thead' | 'tbody' | 'tfoot';
export type TableSectionComponentHTMLProps =
  React.ComponentPropsWithoutRef<TableSectionComponent>;
//   React.ComponentPropsWithoutRef<'thead'> &
//     React.ComponentPropsWithoutRef<'tbody'> &
//     React.ComponentPropsWithoutRef<'tfoot'> &
//     React.ComponentPropsWithoutRef<'div'>;

export interface TableSectionContextProps
  extends TableSectionComponentHTMLProps {
  Component: TableSectionComponent;
}

export const TableSectionContext = createContext<
  TableSectionContextProps | undefined
>(undefined);

export interface TableSectionProps extends TableSectionComponentHTMLProps {
  Component?: TableSectionComponent;
  themeComponent?: string;
}

export default function TableSection(
  props: React.PropsWithChildren<TableSectionProps>
) {
  const [htmlProps, { Component = 'tbody', themeComponent, children }] =
    extractComponentProps(props, ['Component', 'themeComponent', 'children']);

  const theme = useContext(ThemeContext);

  let sectionTypeThemeComponent: string | undefined;
  switch (Component) {
    case 'thead':
      sectionTypeThemeComponent = 'tableHeader';
      break;
    case 'tfoot':
      sectionTypeThemeComponent = 'tableFooter';
      break;
    default:
      sectionTypeThemeComponent = 'tableBody';
      break;
  }

  const colorClassNames = theme.findColor(
    themeComponent,
    sectionTypeThemeComponent,
    'table',
    'primary'
  );

  return (
    <TableSectionContext.Provider value={{ Component }}>
      <Component
        {...htmlProps}
        className={`w-100 ${colorClassNames} ${htmlProps.className || ''}`}>
        {children}
      </Component>
    </TableSectionContext.Provider>
  );
}
