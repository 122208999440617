import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import GettGoServicesConfig from '../config/gettgo-svcs';
import InvalidSessionError from '../models/errors/invalid-session-error';
import NotFoundError from '../models/errors/not-found-error';
import RemoteApiError from '../models/errors/remote-api-error';
import { SessionInfo } from '../models/session-info';

export interface GettGoApiClientProps {
  servicesConfig: GettGoServicesConfig;
}

export type QueryParamsType = {
  [name: string]: string | number | (string | number)[];
};

//TODO: Change to Axios?
export default class GettGoApiClient {
  static USER_AGENT = 'mtb-installments-api-client';
  static API_REALM = 'mtb-installments';
  static API_NONCE_HEADER_KEY = 'x-api-nonce';
  static API_REALM_HEADER_KEY = 'x-api-realm';

  props: GettGoApiClientProps;
  // restClient: RestClient;
  currentSession?: SessionInfo;

  constructor(props: GettGoApiClientProps) {
    this.props = props;
    // this.restClient = new RestClient(GettGoApiClient.USER_AGENT, props.servicesConfig.baseUrl);
  }

  public setCurrentSession(session: SessionInfo) {
    this.currentSession = session;
  }

  public async get<TResp>(
    endpoint: string,
    reqParams: QueryParamsType = {}
  ): Promise<TResp> {
    const url = new URL(endpoint, this.props.servicesConfig.baseUrl);
    // const reqStr = `GET ${url}`;

    const reqOpts = this.getRequestOptions();
    try {
      const resp = await axios.get(url.href, {
        ...reqOpts,
        params: snakecaseKeys(reqParams, { deep: true }),
      });

      return this.convertResponse<TResp>(resp);
    } catch (err) {
      return this.translateError(err);
    }
  }

  public async post<TReq, TResp>(
    endpoint: string,
    reqData?: TReq
  ): Promise<TResp> {
    const url = new URL(endpoint, this.props.servicesConfig.baseUrl);
    // const reqStr = `POST ${url}`;
    const translatedReqData = snakecaseKeys(reqData as any, { deep: true });

    const reqOpts = this.getRequestOptions();
    try {
      const resp = await axios.post(url.href, {
        ...reqOpts,
        data: translatedReqData,
      });

      return this.convertResponse<TResp>(resp);
    } catch (err) {
      return this.translateError(err);
    }
  }

  public async put<TReq, TResp>(
    endpoint: string,
    reqData?: TReq
  ): Promise<TResp> {
    const url = new URL(endpoint, this.props.servicesConfig.baseUrl);
    // const reqStr = `PUT ${url}`;
    const translatedReqData = snakecaseKeys(reqData as any, { deep: true });

    const reqOpts = this.getRequestOptions();
    try {
      const resp = await axios.put(url.href, {
        ...reqOpts,
        data: translatedReqData,
      });

      return this.convertResponse<TResp>(resp);
    } catch (err) {
      return this.translateError(err);
    }
  }

  public async del<TResp>(
    endpoint: string,
    reqParams: QueryParamsType = {}
  ): Promise<TResp> {
    const url = new URL(endpoint, this.props.servicesConfig.baseUrl);
    // const reqStr = `DELETE ${url}`;

    const reqOpts = this.getRequestOptions();
    try {
      const resp = await axios.delete(url.href, {
        ...reqOpts,
        params: snakecaseKeys(reqParams, { deep: true }),
      });

      return this.convertResponse<TResp>(resp);
    } catch (err) {
      return this.translateError(err);
    }
  }

  private getRequestOptions(): AxiosRequestConfig {
    const headers: Record<string, string | number | boolean> = {
      [GettGoApiClient.API_REALM_HEADER_KEY]: GettGoApiClient.API_REALM,
    };
    if (this.currentSession?.nOnceToken) {
      headers[GettGoApiClient.API_NONCE_HEADER_KEY] =
        this.currentSession.nOnceToken;
    }

    return headers;
  }

  private convertResponse<T>(resp: AxiosResponse): T {
    if (typeof resp.data === 'undefined' || resp.data === null) {
      return resp.data as T;
    }

    return camelcaseKeys(resp.data) as T;
  }

  private translateError<T>(err: any): T {
    if (err.response) {
      let errMsg = `Got response: [${err.response.status}] ${
        err.response.data?.message || err.response.statusText
      }`;
      switch (err.response.status) {
        case 401:
        case 403:
          throw new InvalidSessionError(err.response.status, err);
        case 400:
          throw new RemoteApiError(errMsg, err.response.status, err);
        case 404:
          throw new NotFoundError(err.request?.path, '', err);
          break;
        default:
          throw new RemoteApiError(errMsg, undefined, err);
      }
    }

    throw err;
  }
}
