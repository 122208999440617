import React from 'react';

import { UserSessionProps } from '../components/Dropdowns/UserDropdown';
import { NavLinkProps } from '../components/Links/NavLink';
import IndexNavbar from '../components/Navbars/IndexNavbar';
import Sidebar from '../components/Sidebar/Sidebar';

export interface DefaultLayoutProps {
  appTitle: string;
  sessions?: UserSessionProps[];
  headerNavLinks?: NavLinkProps[];
  sidebarNavLinks?: NavLinkProps[];
}

export default function DefaultLayout({
  appTitle,
  sessions,
  headerNavLinks = [],
  sidebarNavLinks = [],
  children,
}: React.PropsWithChildren<DefaultLayoutProps>) {
  return (
    <>
      <Sidebar navLinks={sidebarNavLinks} />
      <div className="md:ml-64">
        {/* Header */}
        <div className="md:pt-16 pb-32">
          <div className="px-4 md:px-10 mx-auto w-full">
            <IndexNavbar
              appTitle={appTitle}
              navLinks={headerNavLinks}
              sessions={sessions}
            />
            {children}
          </div>
        </div>
      </div>
    </>
  );
}
