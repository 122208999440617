import React, { createContext, useContext, useState } from 'react';

import Order from '../models/order';
import ProviderCode from '../models/provider-code';
import { GetOrdersParams, OrdersServiceFactory } from '../services/orders';
import { useSessionsContext } from './sessions';

export interface OrdersContextProps {
  loading: boolean;
  orders: Order[];
  error?: Error;
  reload: () => void;
}

export const OrdersContext = createContext<OrdersContextProps | undefined>(
  undefined
);

export interface OrdersContextProviderProps {
  providers: ProviderCode[];
  ordersServiceFactory?: OrdersServiceFactory;
  searchParams?: GetOrdersParams;
  onError?: (err: Error) => void;
}

export function OrdersContextProvider({
  providers,
  ordersServiceFactory,
  searchParams,
  onError,
  children,
}: React.PropsWithChildren<OrdersContextProviderProps>) {
  const { sessionsMap } = useSessionsContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [error, setError] = useState<Error | undefined>();

  React.useEffect(() => {
    //cleanup
    return () => {};
  }, []);

  React.useEffect(() => {
    reload();
  }, [ordersServiceFactory, searchParams]);

  function reload() {
    if (!ordersServiceFactory || !searchParams || providers.length < 1) {
      return;
    }

    (async function () {
      setLoading(true);
      const proms = providers.map((provider) => {
        const session = sessionsMap[provider];
        const ordersSvc = ordersServiceFactory.get(provider, { session });
        return ordersSvc.getOrders(searchParams);
      });
      try {
        const provResults = await Promise.all(proms);
        setLoading(false);
        let _orders = provResults.flatMap((order) => order);
        setOrders(_orders);
      } catch (err) {
        console.log(err);
        setLoading(false);
        setError(err as Error);
        onError && onError(err as Error);
      }
    })();
  }

  return (
    <OrdersContext.Provider value={{ loading, orders, error, reload }}>
      {children}
    </OrdersContext.Provider>
  );
}

export function useOrdersContext(): OrdersContextProps {
  const context = useContext(OrdersContext);
  if (!context) {
    throw new Error('OrdersContext Provider not found');
  }

  return context;
}
