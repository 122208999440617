import AppConfig from '../config/app';
import ProviderCode from '../models/provider-code';
import { SessionInfo } from '../models/session-info';
import { Factory } from '../utils/factory';
import { GettGoSessionsServiceImpl } from './sessions-gettgo';

export default interface SessionsService {
  getCurrentSession: () => Promise<SessionInfo>;
  getNOnce: () => Promise<SessionInfo>;
}

export class SessionsServiceFactory extends Factory<SessionsService> {
  constructor(appConfig: AppConfig) {
    super('SessionsService');

    const enabledProvidersSet = new Set(appConfig.enabledProviders);
    if (enabledProvidersSet.has(ProviderCode.GettGo)) {
      this.register(
        ProviderCode.GettGo,
        (_opts: any) =>
          new GettGoSessionsServiceImpl({
            servicesConfig: appConfig.gettGoServices,
          }),
        false
      );
    }
    //TODO: Hero
  }
}
